.discount-code {
  margin-top: 40px;
}
.discount-from {
  position: relative;
}
.update-cart {
  display: flex;
  align-items: center;
  justify-content: end;
}
.updates-cart.back-shoping {
  margin-left: 20px;
}
.back-shoping a {
  font-size: 18px;
  line-height: 18px;
  color: #13172b;
  font-weight: 400;
  border-bottom: 1px solid #ec4899;
}
/* subtotal-wraper */
.subtotal-wraper {
  padding: 26px;
  border-radius: 5px;
  background: #f9f3f0;
}
.subtotal-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4f2ff;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.subtotal-top h3 {
  font-size: 20px;
  line-height: 20px;
  color: #13172b;
  font-weight: 400;
}
.subtotal-top .subtotal-amount {
  color: #ff497c;
}
.shiping ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
}
.shipping-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shiping {
  margin-bottom: 20px;
}
.shiping h4 {
  font-size: 16px;
  line-height: 26px;
  color: #13172b;
  margin-bottom: 20px;
  display: block;
  font-weight: 400;
}
.shipping-right span {
  font-size: 16px;
  line-height: 16px;
  color: #5a5858;
  font-weight: 400;
}
.shipping-calculate h3 {
  font-size: 16px;
  color: #13172b;
  font-weight: 400;
  margin-bottom: 15px;
}
.select-country {
  width: 100%;
  border: 1px solid #e4f2ff;
  border-radius: 10px;
  height: 45px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.select-country select {
  width: 100%;
  border: none;
  display: block;
  cursor: pointer;
  height: 100%;
  color: #a4a4a4;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  background: none;
}
.select-country select:focus-visible {
  outline: #c3e2ff;
}
.total-bottom {
  margin-top: 35px;
  border-bottom: none;
  padding-bottom: 0;
}
.checkout button {
  background: #ff497c;
  display: block;
  height: 50px;
  line-height: 48px;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid transparent;
  width: 100%;
}
.checkout a:hover {
  background: none;
  border: 1px solid#ff497c;
  color: #13172b;
}
@media (max-width: 767.98px) {
  .back-shoping {
    display: inherit;
  }
}
