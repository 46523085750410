/* fewture-v1 */
.fewture-v1-area {
  margin-top: -81px;
  z-index: 9;
  position: relative;
}
.fewture-v1-wrap {
  max-width: 800px;
  height: 160px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 10px 10px 25px 0px #00000014;
  border-radius: 3px;
}
.single-fewture-v1 {
  padding: 20px 30px;
}
/* deal-of-weak-area-start */
.deal-weak-bg1 {
  padding: 80px 50px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.deal-of {
  text-align: center;
  padding: 20px 10px;
  background: #816af61c;
  border-radius: 500px;
  box-shadow: 1px 0px 22px  #39373712;
}
.deal-weak-sheap {
  margin-top: 8px;
}
.deal-of span {
  font-size: 20px;
  line-height: 20px;
  align-items: center;
  color: #13172b;
  text-transform: capitalize;
  font-weight: 500;
  font-family: Josefin Sans;
}
.deal-of h5 {
  font-size: 30px;
  line-height: 29px;
  color: #ff497c;
  font-weight: 600;
  margin-bottom: 5px;
}
.deal-weak-countdown .offer {
  font-size: 16px;
  line-height: 18px;
  color: #13172b;
  display: block;
  margin-bottom: 20px;
}
.deal-weak-img {
	height: 100%;
}
.deal-weak-img img {
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 1399px) {
  .fewture-v1-wrap {
    width: 850px;
  }
}
@media (max-width: 991px) {
  .fewture-v1-area {
    margin-top: -59px;
  }
  .fewture-v1-wrap {
    height: 116px;
  }
  .single-fewture-v1 {
    padding: 13px 13px;
  }
  .deal-weak-bg1 {
    padding: 31px 24px;
  }
  .deal-of span {
    font-size: 16px;
  }
  .deal-of h5 {
    font-size: 24px;
    line-height: 22px;
  }
  .deal-of {
    text-align: center;
    height: 100px;
    width: 100px;
  }
}
@media (max-width: 767.98px) {
  .fewture-v1-wrap {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: auto;
  }
  .single-fewture-v1 {
    padding: 15px 24px;
  }
  .f-content h3 {
    font-size: 18px;
  }
  .deal-weak-right {
    padding-top: 306px;
  }
  .pl-12 {
    padding-left: 12px;
  }
}
@media (max-width: 575.98px) {
  .deal-weak-right {
    padding-top: 265px;
  }
}
