.herosection-v1 {
  padding: 140px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.hero-slider-area{
  overflow: hidden;
}
.hero-slider .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  overflow: hidden;
  padding: 0;
}
.right-img img {
	position: absolute;
	right: 100px;
	bottom: 0;
	width: 710px;
}
.hero-bg{
  background: #f5f5f5;
}
.hero-v1-title span {
  color: #ff497c;
  font-family: "Jost", sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  display: block;
}
.hero-v1-title h2 {
	font-size: 70px;
	line-height: 80px;
	color: #13172b;
	font-weight: 700;
	margin-bottom: 15px;
}
.hero-v1-title p {
  font-weight: 400;
  font-size: 18px;
  color: #5a5858;
  line-height: 26px;
  margin-bottom: 30px;
}


.active .hero-v1-title h2, .active .hero-v1-title h5, .active .hero-v1-title a{
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.active .hero-v1-title h5{
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  animation-name: fadeInRight;
  -webkit-animation-name:fadeInRight;
}
.active .fhero-v1-title a {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}


/* responsive */
@media (max-width: 1600px) {
  .right-img img{
    position: inherit;
    width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .hero-v1-title h2 {
    font-size: 45px;
    line-height: 60px;
  }
}
@media (max-width: 991.98px) {
  .herosection-v1 {
    padding: 120px 0;
  }
  .hero-v1-title span {
    font-size: 17px;
  }
  .hero-v1-title h2 {
    font-size: 36px;
    line-height: 46px;
  }
  .hero-v1-title p {
    font-size: 15px;
  }
}
@media (max-width: 768.98px) {
  .hero-v1-title h2 {
    font-size: 24px;
    line-height: 26px;
  }
  .hero-v1-title span {
    font-size: 15px;
  }
}
@media (max-width: 576.98px) {
  .herosection-v1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}