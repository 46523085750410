.categories-slider .swiper.swiper-initialized.swiper-horizontal {
  padding-bottom: 32px;
}
.categories-slider .button-next-slide2 {
  margin-left: 70px;
}
@media (max-width: 420px) {
  .categories-slider .button-next-slide2 {
    margin-left: 51px;
  }
}
