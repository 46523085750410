.scroolbar {
  display: inherit;
  margin: 0;
  padding: 0;
}
.scroolbar svg {
  position: fixed;
  bottom: 10px;
  font-size: 2px;
  z-index: 1;
  cursor: pointer;
  color: #ffffff;
  height: 25px;
  background: #ff497c;
  font-weight: 200;
  border-radius: 36PX;
  width: 26px;
  padding: 9px;
  right: 1%;
  transition: all 0.3s ease;
}
.scroolbar svg:hover {
  background: #13172b;
}
