/* promotional-v1-area */
.promo-card {
  background: #f5f5f5;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: all .4s ease-in;
  z-index: 1;
}
.promo-card:hover{
  background:#09185d;
}
.promo-shape{
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  transition: all .4s ease-in-out;
}
.promo-card:hover .promo-shape{
  bottom: 0;
}
.promo-content {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.promo-card-img {
  width: 50%;
  position: relative;
  height: 250px;
}

.promo-title {
  padding: 25px 0px 0px 25px;
}
.man-img {
  position: absolute;
  bottom: 24px;
  left: 4px;
  z-index: 9;
  transition: all .4s ease;
}
.promo-card:hover .man-img{
  transform: scale(1.1);
}
.promo-card .bottom-img {
  bottom: 0px;
}
.man-img img {
  width: 80%;
  float: right;
  margin-right: 10px;
}
.shape-img {
  position: absolute;
  top: 0;
  right: 0;
}
.shape-img img {
  width: 100%;
}
.promo-title h6 {
  color: #5a5858;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}
.promo-card:hover .promo-title h6{
  color: #ffff;
}
.promo-title h6.discountv1 {
  margin-bottom: 35px;
}
.promo-title .badgebg {
  background: #ff497c;
}
.discountv1 span {
  background: #3577f0;
  margin: 0px 5px;
  color: #fff;
}
.promo-title h3 {
  font-size: 20px;
}
.promotional-v1-area .promo-1 .promo-title {
  padding: 32px 0 0 18px;
  overflow: hidden;
  width: 60%;
  z-index: 1;
}
