/* about-company-area */
.company-img {
  position: relative;
  margin-right: 30px;
}
.company-img .company {
  width: 100%;
  border-radius: 10px;
}
.expreance-company {
  background: #ff497c;
  position: absolute;
  padding: 8px 20px;
  align-items: center;
  display: flex;
  width: 311px;
  left: 0;
  right: -56px;
  margin: auto;
  bottom: 12%;
  border-radius: 5px;
  border: 8px solid #ffffff;
}
.expreance-company h6 {
  display: inline-block;
  font-size: 30px;
  left: 30px;
  color: #ffffff;
  font-weight: 600;
  margin-left: 10px;
}
.expreance-company h6 span {
  display: inline-block;
  font-size: 20px;
  left: 20px;
  color: #ffffff;
  font-weight: 400;
}
.company-content p {
  line-height: 26px;
  text-transform: none;
  margin-bottom: 40px;
}
.video-area {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4f2ff;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.video-content ul li {
  font-style: italic;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 5px 0px;
  color: #13172b;
}
.video-content ul li svg{
  margin-right: 10px;
  color: #ff497c;
}
.video-content ul li img {
  margin-right: 10px;
}
.about-author {
  display: flex;
  align-items: center;
}
.author-img {
  display: flex;
  align-items: center;
}
.author-info {
  margin-left: 15px;
}
.author-info h4 {
  font-size: 20px;
  line-height: 20px;
  color: #13172b;
  font-weight: 500;
}
.author-info span {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #5a5858;
}
.auhor-signature {
  margin-left: 76px;
}
.video img {
  width: 142px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 35px 0px #0000001a;
}
.video .click {
  position: relative;
}
.video .click:before {
  position: absolute;
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 26px;
  border-color: transparent transparent transparent #ffff;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}
.video button {
  background: none;
  padding: 0;
  margin-right: 20px;
}
/* responsive */
@media (max-width: 575.98px) {
  .expreance-company {
    right: -6px;
  }
  .company-img {
    margin-right: 0px;
  }
  .expreance-company {
    right: 0;
  }
  .expreance-company {
    width: 300px;
  }
}
@media (max-width: 420px) {
  .video img {
    width: 100px;
    padding: 5px;
  }
  .video-content ul li {
    font-size: 9px;
  }
  .author-img img {
    width: 50px;
  }
  .auhor-signature {
    margin-left: 15px;
  }
  .auhor-signature img {
    width: 100px;
  }
  .author-info h4 {
    font-size: 16px;
  }
  .author-info span {
    font-size: 12px;
  }
}
