.testimonial-slidev1
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  text-align: center;
}
.testimonial-slidev1 .swiper-pagination .swiper-pagination-bullet {
  background-color: #ff497c;
  border-radius: 500px;
  cursor: pointer;
  display: inline-block;
  height: 6px;
  margin: 0 6px;
  position: relative;
  transition: all 0.3s ease;
  width: 16px;
  opacity: 0.5;
}
.testimonial-slidev1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ff497c;
  opacity: 1;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding-bottom: 45px;
}
