.office-single {
  padding: 20px 20px;
  border: 1px solid #e4f2ff;
  border-radius: 5px;
  text-align: center;
  padding-bottom: 30px;
}
.world-office-wraper img {
  width: 100%;
  margin-bottom: 20px;
}
.world-office-wraper h4 {
  font-size: 26px;
  line-height: 36px;
  color: #13172b;
  font-weight: 600;
  margin-bottom: 20px;
}
.office-contact-info {
  padding: 0px 30px;
}
.contact-details a {
  font-size: 16px;
  line-height: 36px;
  color: #5a5858;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-details {
  margin-bottom: 22px;
}
.contact-details a svg {
  color: #ff497c;
  margin-right: 5px;
}
.contact-button a {
  padding: 15px 37px;
  background: #ff497c;
  color: #ffff;
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}
.contact-button a:hover {
  background: none;
  border: 1px solid#ff497c;
  color: #13172b;
}
/* contact-area */
.contact-area {
  background: #f5f5f5;
}
.contact-form h2 {
  font-size: 30px;
  line-height: 30px;
}
.contact-form span {
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
  margin-bottom: 30px;
  display: block;
}
