.pro-search-result-area .search-product {
  padding: 20px;
  border: 1px solid #fff;
  align-items: center;
  padding: 30px;
  border-radius: 5px;
  transition: 0.3s;
  margin-bottom: 20px;
  display: flex;
}
.pro-search-result-area .search-product-img a img {
  background-color: #f7f7f7;
  border-radius: 5px;
  display: block;
  transition: 0.3s;
  overflow: hidden;
  margin-right: 30px;
  width: 100px;
}
.pro-search-result-area .search-content {
  flex: 1;
  position: relative;
  padding-right: 60px;
}
.pro-search-result-area .search-content .product-rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.pro-search-result-area .search-content .product-rating .rating-icon {
  color: #fcc013;
}
.pro-search-result-area .search-content .product-rating .total-rating {
  font-weight: 500;
  margin-left: 10px;
  display: inline-block;
}
.pro-search-result-area .search-content .product-title a {
  margin-bottom: 10px;
  font-size: 20px;
}
.pro-search-result-area .search-content .product-price h4 {
  font-size: 18px;
  font-weight: 600;
  color: #13172b;
}
.pro-search-result-area .search-content .product-price del {
  color: #a4a4a4;
  margin-left: 10px;
}
.pro-search-result-area .search-content .product-cart {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.pro-search-result-area .search-content .product-cart a {
  text-align: center;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border: 1px solid #efefef;
  border-radius: 5px;
  color: #13172b;
  font-size: 18px;
  font-weight: 500;
  transition: 0.3s;
  margin-bottom: 10px;
}
.pro-search-result-area .search-content .product-cart a:hover {
  color: #fff;
  background: #ff497c;
}
