.single-input.review-textarea {
  padding-bottom: 20px;
}
.single-input.review-textarea textarea {
  background: #e4f2ff;
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 10px;
  height: 200px;
  padding: 20px;
}
.single-input.review-textarea ::placeholder {
  color: #a4a4a4;
}
/* faq-massage */
.single-input.faq-massage textarea {
  width: 100%;
  height: 162px;
  border-radius: 5px;
  border: none;
  padding: 20px;

}
.single-input.faq-massage ::placeholder {
  color: #a4a4a4;
}
