.fewtureproduct-img {
  text-align: center;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  padding: 56px 30px 71px 30px;
  padding-bottom: 0;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.p-relative{
  position: relative;
}
.second-product {
  position: absolute;
  right: -100%;
  top: 0;
  transition: all .5s ease-in-out;
  opacity: 0;
}
.fewture-single-product:hover .second-product{
  opacity: 1;
  right: 0;
} 
.fewtureproduct-img img {
  width: 100%;
  transition: all .5s ease-in-out;
}
.fewture-single-product {
  transition: all .5s ease-in-out;
  overflow: hidden;
}
.fewture-single-product:hover .first-product {
  margin-left: -100%;
  opacity: 0;
}
/* single-shop-bg */
.fewture-single-product.single-shop .fewtureproduct-img {
  background: #eafaea;
  overflow: hidden;
}
.fewture-single-product.single-shop .fewture-cetagory-content {
  background: #eafaea;
}
.discount {
  position: absolute;
  top: 9px;
  left: 11px;
  padding: 6px 15px;
  background: #ff497c;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  border-radius: 3px;
}
.discount.Sale {
  background: #fcc013;
}
.discount.new-dis {
  background: #3577f0;
  animation: mymove .8s infinite;
}
.fewtureproduct-img a {
  display: block;
}
.fewture-cetagory-hover {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  position: absolute;
  top: 55px;
  right: -81px;
}
.fewture-cetagory-hover ul {
  position: absolute;
  text-align: center;
  padding: 20px;
  top: 0;
  right: 0;
}
.fewture-cetagory-hover.shop-link ul {
  position: absolute;
  text-align: center;
  padding: 0px;
  top: 0;
  right: 0;
}
.fewture-cetagory-hover ul li {
  margin: 8px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 0;
  background: #fff;
  box-shadow: 0px 0px 12px 0px #00000014;
}
.fewture-cetagory-hover > ul li > a {
  color: #111;
  font-size: 18px;
  line-height: 55px;
}
.fewture-cetagory-hover > ul li:hover a {
  color: #ff497c;
}
.fewture-single-product:hover .fewture-cetagory-hover {
  visibility: visible;
  opacity: 1;
  right: 0;
}
.fewture-btn {
  position: absolute;
  left: 50%;
  bottom: -20%;
  transition: all .5s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.fewture-btn a {
  border-radius: 10px;
  background: #ff497c;
  border: 1px solid #ff497c;
  color: #fff;
  font-size: 16px;
  line-height: 14px;
  text-transform: capitalize;
  padding: 16px 18px;
  text-align: center;
  transform: translateX(-50%);
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
}
.fewture-single-product:hover .fewture-btn {
  opacity: 1;
  visibility: visible;
  bottom: 6%;
}
.fewture-btn a:hover {
  background: #fff;
  border: 1px solid #ff497c;
  color: #13172b;
}
.fewture-cetagory-content {
  padding: 25px;
  background: #fff;
  border-radius: 5px;
  margin-top: 1px;
}
.fewture-cetagory-info {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  align-items: center;
}
.fewture-cetagory-variant {
  width: 56px;
  display: flex;
  justify-content: space-between;
}
.fewture-cetagory-variant .color-one,
.fewture-cetagory-variant .color-two,
.fewture-cetagory-variant .color-three {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 3px rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.fewture-cetagory-variant .color-one:hover,
.fewture-cetagory-variant .color-two:hover,
.fewture-cetagory-variant .color-three:hover {
  box-shadow: none;
}
.fewture-cetagory-variant .color-one {
  background: #ee52f1;
}
.fewture-cetagory-variant .color-two {
  background: #49181e;
}
.fewture-cetagory-variant .color-three {
  background: #6b4208;
}
.fewtureproduct-default1 .color-one {
  background: #32281b;
}
.fewtureproduct-default2 .color-one {
  background: #975b6c;
}
.fewtureproduct-default1 .color-three {
  background: #11264e;
}
.fewtureproduct-default2 .color-two {
  background: #122343;
}
.fewtureproduct-default3 .color-three {
  background: #2e2210;
}
.fewture-cetagory-review svg {
  color: #fcc013;
  margin-right: 5px;
}
.fewture-cetagory-review.rating svg {
  color: #696969;
}
.fewture-cetagory-review span {
  color: #696969;
  font-size: 14px;
  font-weight: 400;
}
.fewture-cetagory-title a {
  font-size: 18px;
  color: #5a5858;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 14px;
  display: block;
  font-family: Josefin Sans;
}
.fewture-cetagory-title h3 {
  font-size: 20px;
  color: #13172b;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
}
.fewture-cetagory-title del {
  color: #818681;
  margin-left: 19px;
}
/* fewturev1-area */
.fewture-single-product.single-shop .fewtureproduct-img.section-bg-v1 {
  background: #f5f5f5;
}
.fewture-single-product.single-shop .fewture-cetagory-content.content-bg {
  background: #f5f5f5;
}
/* shop-area */
.fewtureproduct-img.shop-section {
  height: auto;
}
.fewtureproduct-img.shop-section img {
  width: 100%;
}
/* pelated-products-area */
.pelated-products .fewtureproduct-img {
  background: #f5f5f5;
}
.pelated-products .fewtureproduct-img {
  background: #f5f5f5;
}
@media only screen and (min-width: 1399.98px) {
  .fewtureproduct-img.shop-section img {
    width: 100%;
  }
}
@media (max-width: 1199.98px) {
  .fewture-btn a {
    padding: 16px 30px;
  }
}
