/* coupon-area */
.coupon {
  padding: 22px;
  background: #f5f5f5;
  border-radius: 0px 0px 5px 5px;
  border-top: 2px solid#FF497C;
}
.coupon span {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #5a5858;
}
.coupon-do {
  position: relative;
  margin-left: 37px;
}
.coupon-do::after {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  border: 1px solid #7abfff;
  left: -36px;
}
.coupon-do::before {
  position: absolute;
  content: "";
  width: 22px;
  height: 3px;
  left: -36px;
  background: #7abfff;
}
.coupon .coupon-code {
  color: #ff497c;
  cursor: pointer;
  margin-left: 5px;
}
.discount-from.discountes {
  display: none;
  width: 340px;
}
.discount-from.discountes.active {
  display: block;
  margin-top: 20px;
}
/* billing-form */
.billing-form h2 {
  color: #13172b;
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 30px;
}
.your-chose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 0px 15px;
  background: #f5f5f5;
}
.fild-name {
  width: 100%;
  position: relative;
}
.fild-name span {
  display: block;
  color: #a4a4a4;
  font-size: 14px;
  position: absolute;
  font-weight: 400;
  line-height: 24px;
  top: -3px;
}
.fild-name select {
  width: 100%;
  border: none;
  display: block;
  cursor: pointer;
  height: 100%;
  color: #13172b;
  font-size: 16px;
}
.fild-name select:focus-visible {
  outline: #c3e2ff;
}
.fild-name select option {
  padding: 0;
}
.your-chose svg {
  color: #13172b;
}
.fild-name .country {
  font-size: 16px;
  color: #13172b;
  padding-top: 23px;
  margin-left: -3px;
  background: none;
}
.checkbox-create {
  margin-top: 12px;
  margin-bottom: 20px;
}
.checkbox-create h2 {
  margin-bottom: 16px;
}
.checkbox-create.shipping-checkbox {
  margin-bottom: 0;
}
/* order-summary */
.order-summary-wraper h2 {
  color: #13172b;
  font-size: 26px;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 30px;
}
.order-summary-enset {
  border-radius: 5px;
}
.total-inner-pd {
  padding: 30px;
  background: #f9f3f0;
}
.single-order-list {
  background: #ffff;
  padding: 15px;
}
.product-list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e4f2ff;
}
.product-list-title span {
  color: #13172b;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
}
.order-list {
  border-bottom: 1px solid #e4f2ff;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.order-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
.order-list ul li:last-child {
  margin: 0;
}
.order-list ul li span {
  color: #5a5858;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.order-list ul li span:first-child {
  width: 50%;
}
.order-list ul li span:nth-child(2) {
  width: 25%;
}
.order-list ul li sapn:last-child {
  width: 25%;
}
.single-order-list.subtotal-list .product-list-title {
  padding-bottom: 0;
  margin-bottom: 15px;
  border-bottom: none;
}
.single-order-list.subtotal-list .order-list ul li span:first-child {
  width: auto;
}
.single-order-list.subtotal-list .order-list ul li span:nth-child(2) {
  width: auto;
  font-size: 14px;
}
.total-order-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffff;
  padding: 15px;
}
.total-order-price span {
  font-size: 20px;
  line-height: 20px;
  color: #13172b;
  font-weight: 400;
}
.total-order-price .order-rt {
  color: #ff497c;
}
.payment-refarence {
  padding: 30px;
  background: #f5f5f5;
}
.refarence-text {
  margin-left: 10px;
}
.refarence-text h4 {
  font-size: 16px;
  line-height: 26px;
  color: #13172b;
  font-weight: 400;
}
.refarence-text P {
  font-size: 14px;
  line-height: 24px;
  color: #5a5858;
  font-weight: 400;
  margin-left: 16px;
}
.cash-on {
  padding: 30px;
  background: #f9f3f0;
}
.order-btn.checkout {
  margin-top: 26px;
}
@media (max-width: 991.98px) {
  .order-summary-wraper {
    margin-top: 50px;
  }
}
@media (max-width: 767.98px) {
  .coupon-area.section-ptb {
    padding-bottom: 30px;
  }
  .coupon span {
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  .total-inner-pd {
    padding: 10px;
  }
  .back-shoping a {
    font-size: 13px;
  }
  .discount-from.discountes {
    width: 100%;
  }
}
