.our-team-slider {
  padding-bottom: 70px;
  position: relative;
}
.our-team-next-prev {
  position: absolute;
  top: -78px;
  right: 123px;
}
.our-team-slider
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding-bottom: 0px;
}
.our-team-next-prev div {
  position: absolute;
  width: 50px;
  text-align: center;
  height: 50px;
  cursor: pointer;
  color: #ff497c;
  border: 1px solid #ff497c;
  border-radius: 50px;
  transition: 0.3s;
}
.our-team-next-prev div:hover {
  background: #ff497c;
  color: #ffff;
}
.our-team-next-prev div.swiper-button-disabled {
  background: #ff497c;
  z-index: 999;
  opacity: 0.5;
}
.our-team-next-prev div.button-next-slide {
  margin-left: 70px;
}
.our-team-next-prev div svg {
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}
/* responsive */
@media (max-width: 575.98px) {
  .our-team-next-prev div {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 420px) {
  .our-team-next-prev div {
    width: 35px;
    height: 35px;
  }
  .our-team-next-prev div.button-next-slide {
    margin-left: 51px;
  }
  .our-team-next-prev div svg {
    font-size: 15px;
  }
  .our-team-next-prev {
    right: 88px;
  }
}
