.header-right {
  float: right;
}
.header-right ul li {
  display: inline-block;
  margin-left: 28px;
  cursor: pointer;
  position: relative;
  line-height: 10px;
}

.header-right ul li.dyna svg {
  margin-bottom: 0px;
}
.header-right ul li svg {
  color: #13172b;
  font-size: 17px;
  transition: all 0.3s ease;
}
.header-right ul li .item-count {
  color: #13172b;
  font-size: 18px;
  position: absolute;
  top: -9px;
  right: -8px;
  height: 15px;
  width: 15px;
  background: #ff497c;
  text-align: center;
  line-height: 14px;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
}
/* header-right */
.cart-slide {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  z-index: 99999;
  transition: 0.5s;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
}
.mysearch{
  margin-top: 4px;
}
.cart-slide.active {
  right: 0;
}
.cart-close.active {
  transition: all 0.3s ease;
}
.cart-close.active:hover {
  color: #ff497c;
}
.product-rating .icon svg {
  font-size: 10px;
  color: #fcc013;
  margin-right: 2px;
}
.cart-slide .cart-content {
  background: #f5f5f5;
  padding: 60px 50px;
  width: 600px;
  height: 100%;
  overflow: auto;
}
.cart-slide .cart-content::-webkit-scrollbar {
  width: 6px;
}
.cart-slide .cart-content::-webkit-scrollbar-track {
  background: #6c6c6c;
}
.cart-slide .cart-content::-webkit-scrollbar-thumb {
  background: #3577f0;
}
.cart-slide .cart-content::-webkit-scrollbar-thumb:hover {
  background: #fcc013;
}
.cart-slide .cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #fff;
  padding-bottom: 18px;
  margin-bottom: 18px;
}
.cart-slide .cart-header .cart-title {
  font-size: 26px;
  color: #13172b;
  margin-bottom: 0;
  font-weight: 400;
}
.cart-slide .cart-header .cart-close {
  background: none;
}
/* search-heare */
.search-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: -100%;
  background-color: #fff;
  overflow: hidden;
  transition: 0.5s;
  visibility: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.search-box.active {
  visibility: visible;
  top: 0;
}
.cart-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
  margin: 30px;
  color: #13172b;
  transition: 0.3s;
  cursor: pointer;
}
.search-box form {
  height: 50px;
  width: 70vw;
  position: relative;
  margin-bottom: 30px;
}
.search-box button {
  position: absolute;
  right: 0;
  height: 48px;
  width: 48px;
  color: #13172b;
  border: 0;
  border-radius: 50px;
  transition: 0.3s;
  background: none;
  top: 0;
}
.search-box button svg {
  background: #fff;
  padding: 0 5px;
}
.pro-search-result-area {
  width: 70vw;
}
.search-rasult-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.search-rasult-header h6 {
  color: #13172b;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
}
.search-rasult-header a {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
.cart-slide .cart-bottom {
  border-top: 2px solid #fff;
}
.cart-slide .cart-bottom .cart-subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0 30px;
  color: #13172b;
  font-size: 20px;
  font-weight: 400;
}
.cart-slide .cart-bottom .btn-group {
  display: flex;
  justify-content: space-around;
}
.btn-group .button {
  padding: 10px 50px;
}
@media (max-width: 650px) {
  .header-right {
    margin-right: 10px;
  }
  .header-right ul li svg {
    margin-bottom: 2px;
  }
  .cart-slide .cart-content {
    padding: 33px 9px;
    width: 406px;
  }
  .cart-slide .cart-item .item-quantity {
    top: 12%;
  }
  .pro-search-result-area {
    width: 85vw;
  }
  .pro-search-result-area .search-product {
    padding: 7px;
    margin-bottom: 10px;
  }
  .search-rasult-header {
    margin-bottom: 15px;
  }
  .pro-search-result-area .search-content .product-title a {
    margin-bottom: 0px;
    font-size: 13px;
  }
  .pro-search-result-area .search-product-img a img {
    margin-right: 15px;
  }
  .pro-search-result-area .search-content .product-rating {
    font-size: 12px;
  }
  .search-box button svg {
    background: none;
  }
  .search-box button {
    top: 0px;
    z-index: 9;
  }
  .cart-close {
    font-size: 20px;
    margin: 16px 30px;
  }
  .pro-search-result-area .search-content .product-price h4 {
    font-size: 12px;
  }
  .search-form {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media (max-width: 420px) {
  .cart-slide {
    right: -132%;
  }
  .cart-slide .cart-content {
    width: 316px;
  }
  .cart-slide .cart-item .item-quantity {
    width: 82px;
    height: 26px;
  }
  .cart-slide .cart-item .item-img {
    margin-right: 10px;
  }
  .header-right ul li {
    margin-left: 7px;
  }
  .btn-group .button {
    padding: 10px 40px;
  }
  .cart-close {
    margin: 24px 0px;
    margin-right: 10px;
  }
  .search-box .search-form {
    padding: 25px 8px;
  }
  .search-box button {
    height: 36px;
  }
  .pro-search-result-area .search-product-img a img {
    width: 73px;
  }
  .rating-icon svg {
    font-size: 8px;
  }
  .pro-search-result-area .search-content {
    padding-right: 35px;
  }
  .pro-search-result-area {
    width: 100%;
  }
  .product-rating .icon svg {
    margin-bottom: 0;
  }
}
