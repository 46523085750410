.deal-product-single {
  display: flex;
  border-radius: 5px;
  background: #eafaea;
  padding: 30px;
  padding-right: 10px;
}
.deal-product-left {
  width: 165px;
  height: 159px;
  background: #fff;
  border-radius: 5px;
  padding: 16px 28px;
}
.deal-product-img {
  width: 108px;
  height: 126px;
  display: flex;
  flex-direction: column;
}
.deal-product-img img {
  width: 100%;
}
.deal-product-left .deal-product-btn {
  padding: 4px 20px;
  border-radius: 50px;
  background: #ff497c;
  color: #fff;
  font-family: Josefin Sans;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: -3px;
  border: 1px solid transparent;
}
.deal-product-single:hover .deal-product-left .deal-product-btn {
  background: #000;
}
.deal-product-right {
  margin-left: 20px;
}
.deal-product-right h5 {
  font-size: 20px;
  line-height: 30px;
  color: #13172b;
  font-weight: 500;
  text-transform: capitalize;
}
.deal-product-single:hover .deal-product-right h5 {
  color: #ff497c;
}
.deal-product-right .persent {
  display: flex;
}
.deal-product-right .persent h5 {
  margin-left: 5px;
  margin-bottom: 0;
}
.deal-product-right .persent span {
  color: #fff;
  background: #3577f0;
  font-size: 20px;
  line-height: 25px;
  padding: 2px;
  border-radius: 2px;
}
@media only screen and (min-width: 1399.98px) {
  .deal-product-left {
    width: 155px;
  }
  .deal-product-img {
    margin-left: -6px;
  }
  .deal-product-timer {
    width: 208px;
  }
  .deal-product-right {
    margin-left: 10px;
  }
}
@media (max-width: 575.98px) {
  .deal-product-single {
    display: block;
  }
  .deal-product-right {
    margin-left: 0px;
    overflow: hidden;
    margin-top: 22px;
  }
  .deal-product-single {
    text-align: center;
  }
  .deal-product-right .persent {
   justify-content: center;
  }
  .deal-product-timer {
    width: 100%;
  }
  .deal-product-single {
    padding: 30px;
  }
  .deal-product-left {
    width: 100%;
  }
  .deal-product-img img {
    width: auto;
  }
  .deal-product-img {
    align-items: center;
    width: 100%;
  }
}
