.slider {
  width: 100%;
  height: 6px;
  border-radius: 15px;
  background: #c7c7c7;
}
.slider .thumb {
  width: 16px;
  cursor: pointer;
  height: 16px;
  border-radius: 50%;
  background: #ffff;
  box-shadow: 0px 3px 10px 0px #cccccc;
  top: -6px;
}
.track.track-1 {
  background: #ff497c;
  height: 6px;
  border-radius: 25px;
}
.values {
  margin-top: 10px;
}
.values p {
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
  display: inline-block;
  margin-right: 10px;
}
.values h4 {
  color: #13172b;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}
