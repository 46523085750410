
.total-cetagoris {
  position: relative;
}
.next-prev {
  position: absolute;
  left: 0;
  bottom: 70px;
}
.section-bg3{
  background: #eafaea;
}
.next-prev div {
  position: absolute;
  width: 50px;
  text-align: center;
  height: 50px;
  cursor: pointer;
  color: #ff497c;
  border-radius: 50px;
  transition: 0.3s;
  border: 1px solid#ff497c;
}
.next-prev div:hover {
  background: #ff497c;
  opacity: 1;
  color: #fff;
}
.next-prev div.swiper-button-disabled {
  background: #ff497c;
  z-index: 9;
  opacity: 0.5;
}
.next-prev div.button-next-slide {
  margin-left: 70px;
}
.next-prev div svg {
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}
.FewtureProduct-area .main-color {
  height: inherit;
}
/* product-tab */
.shop-section .nav-tabs .nav-link {
  border: none;
  padding: 0;
  margin-left: 23px;
  color: #5a5858;
  border-bottom: 1px solid transparent;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
}
.shop-section .nav-tabs .nav-link.active {
  background: none;
  border-bottom: 1px solid#3577F0;
  color: #3577f0;
}
div#nav-tab {
  border: none;
  float: right;
  margin-top: 28px;
}
@media (max-width: 991.98px) {
  .next-prev {
    top: 0;
    right: 119px;
    left: inherit;
  }
  .next-prev div {
    width: 40px;
    height: 40px;
  }
  .shop-section #nav-tab {
    margin-top: 0px;
  }
  .shop-section .nav-tabs .nav-link {
    font-size: 14px;
    padding: 15px 3px;
    margin-right: 0px;
    margin-left: 10px;
    padding-bottom: 0px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767.98px) {
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  .next-prev {
    right: 87px;
  }
  .fewture-heare {
    padding: 35px 8px;
    padding-bottom: 15px;
  }
  .next-prev div.button-next-slide {
    margin-left: 50px;
  }
  .next-prev div {
    width: 35px;
    height: 35px;
  }
  .next-prev div svg {
    font-size: 15px;
  }
}
