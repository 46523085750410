@media (max-width: 991.98px) {
  .header-area {
    padding: 10px 0px;
  }
  .header-right ul li {
    margin-left: 21px;
  }
}
@media (max-width: 767.98px) {
  .header-right ul li {
    margin-left: 15px;
  }
}
