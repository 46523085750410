.cart-slide .cart-body {
  padding-bottom: 20px;
  flex: auto;
}
.cart-slide .cart-item-list {
  padding: 0;
  margin: 0;
}
.cart-slide .cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px solid #fff;
  padding-bottom: 30px;
  cursor: default;
}
.cart-slide .cart-item.border-non:last-child {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.cart-slide .cart-item span {
  font-size: 12px;
}
.cart-slide .cart-item .item-title {
  font-size: 16px;
  color: #13172b;
  margin-bottom: 10px;
  overflow: hidden;
}
.cart-slide .cart-item .item-title a {
  text-transform: capitalize;
  transition: all 0.3s ease;
}
.cart-slide .cart-item .item-title a:hover {
  color: #ff497c;
}
.product-rating {
  margin-bottom: 10px;
}
.cart-slide .cart-item .item-img {
  height: 100px;
  width: 100px;
  margin-right: 30px;
  position: relative;
  padding: 0;
}
.cart-slide .cart-item .item-img img {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.cart-slide .cart-item .item-img .close-btn {
  font-size: 24px;
  color: #13172b;
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  left: 0;
  margin: -16px 0 0 -12px;
  transition: all 0.3s ease-in-out;
  width: 28px;
  background: #fff;
  height: 28px;
  text-align: center;
  line-height: 16px;
  border: 2px solid#13172b;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.cart-slide .cart-item .item-img .close-btn:hover {
  border: 2px solid#ff497c;
}
@media (max-width: 420px) {
  .cart-slide .cart-item .item-img .close-btn {
    line-height: 8px;
  }
}
