.newsletter-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.newsletter {
  padding: 70px 50px;
}
.newsletter-title {
  margin-bottom: 25px;
}
.newsletter-title span {
  font-size: 16px;
  line-height: 16px;
  color: #ff497c;
  font-weight: 400;
}
.newsletter-title h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  color: #13172b;
  margin-bottom: 0;
}
.newsletter-title h2 span {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  color: #3577f0;
  margin: 0px 5px;
}
.newsletter-form {
  position: relative;
}
.button.newsletter-btn {
  position: absolute;
  top: 3px;
  right: 4px;
  padding: 13px 31px;
  border-radius: 10px;
}
.single-input.newsletterinput input {
  background: #ffffff;
  width: 100%;
  padding: 20px;
  border: none;
  padding-right: 160px;
  border-radius: 10px;
  color: #a4a4a4;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 0px 0px 20px 0px #00000014;
}
.newsletter-right-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 100%;
}
@media (max-width: 991.98px) {
  .newsletter {
    padding: 70px 26px;
    padding-bottom: 0;
  }
  .newsletter-title h2 {
    font-size: 25px;
    line-height: 30px;
  }
  .newsletter-area {
    position: relative;
  }
  .newsletter {
    position: relative;
    z-index: 99;
  }
  .newsletter-right-img {
    display: none;
  }
  .newsletter {
    padding-bottom: 70px;
  }
  .newsletter-right-img img {
    width: 80%;
  }
}
@media (max-width: 420px) {
  .newsletter {
    padding: 70px 12px;
  }
  .newsletter-title h2 {
    font-size: 18px;
  }
  .newsletter-title h2 span {
    font-size: 22px;
  }
  .single-input.newsletterinput input {
    padding: 11px;
    padding-right: 110px;
  }
  button.button.newsletter-btn {
    padding: 4px 16px;
  }
}
