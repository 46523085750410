.blog-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 500px;
  margin-top: 15px;
}
.blog-img img {
  width: 100%;
  border-radius: 5px;
}
.blog-info a svg {
  color: #3577f0;
  font-size: 15px;
}
.blog-img {
  position: relative;
  overflow: hidden;
}
.blog-img::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: '';
  width: 0;
  height: 0;
  background: rgba(255,255,255,.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .4s ease;
}
.single-blog-card:hover .blog-img::before{
  opacity: 1;
  width: 150%;
  height: 150%;
}
.blog-info span {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #5a5858;
  margin-left: 5px;
  transition: all 0.3s ease;
}
.blog-info span:hover {
  color: #ff497c;
}
.blog-link h2 {
  line-height: 30px;
  font-size: 20px;
  margin-bottom: 0;
}
.blog-link h2 a {
  font-size: 22px;
  font-weight: 600;
  color: #13172b;
  transition: all 0.3s ease;
}
.blog-link h2 a:hover {
  color: #ff497c;
}
.blog-link {
  margin: 20px 0px;
}
@media (max-width: 1199.98px) {
  .blog-info span {
    font-size: 11px;
  }
  .blog-link h2 a {
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  .blog-link h2 a {
    font-size: 19px;
  }
  .button.blog-btn {
    padding: 5px 24px;
  }
  .blog-link {
    margin: 12px 0px;
  }
}
@media (max-width: 420px) {
  .blog-info span {
    font-size: 12px;
  }
}
