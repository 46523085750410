.filter-widget {
  background: #f5f5f5;
  padding: 25px 17px;
  border-radius: 5px;
}
.subject-title h2 {
  font-size: 26px;
  line-height: 35px;
  color: #13172b;
  font-weight: 600;
  margin-bottom: 20px;
}
.single-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.single-input.filter span {
  font-size: 16px;
  color: #696969;
  font-weight: 400;
  line-height: 26px;
}
.filter-items ul li {
  margin: 5px 0px;
}
.prodict-shoting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 20px;
}
.default-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shoting-right {
  display: flex;
  align-items: center;
}
.shoting-left p {
  color: #5a5858;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}
.shoting-left p span {
  color: #13172b;
}
.shot-default select {
  border: 1px solid #c3e2ff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 7px;
  padding-bottom: 2px;
  cursor: pointer;
  background: none;
}
.shot-default select option {
  padding: 10px;
}
.shot-default select:focus-visible {
  outline: #c3e2ff;
}
.price-filter {
  margin-top: 1px;
  background: #f5f5f5;
  padding: 25px 17px;
  border-radius: 5px;
}
.price-filter h2 {
  font-size: 26px;
  line-height: 35px;
  color: #13172b;
  font-weight: 600;
  margin-bottom: 20px;
}
.widget-1 {
  margin-top: 1px;
}
/* colors */
.color-mixer ul li {
  display: inline-block;
  margin-right: 8px;
}
.color-mixer .color {
  width: 23px;
  height: 23px;
  border-radius: 500px;
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;
}
.color-mixer .color:hover {
  box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255, 0.7);
}
.color-mixer ul li .color.color-one {
  background: #3577f0;
}
.color-mixer ul li .color.color-two {
  background: #f1511b;
}
.color-mixer ul li .color.color-three {
  background: #13172b;
}
.color-mixer ul li .color.color-four {
  background: #5a5858;
}
.color-mixer ul li .color.color-five {
  background: #f6e5cf;
}
.color-mixer ul li .color.color-six {
  background: #ff497c;
}
/* tag */
.tag ul li {
  display: inline-block;
}
.tag ul li a {
  border: 1px solid #cccccc;
  color: #696969;
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  padding: 9px 13px;
  display: inline-block;
  border-radius: 50px;
  margin-bottom: 4px;
  padding-bottom: 6px;
  margin-right: 3px;
  transition: all 0.3s ease;
}
.tag ul li a:hover {
  background: #ff497c;
  color: #ffffff;
  border-color: #ff497c;
}
/* ads */
.ads {
  background: #6b92da;
  border-radius: 5px;
  margin-top: 1px;
  height: 295px;
  overflow: hidden;
  padding: 30px;
  padding-bottom: 0px;
  position: relative;
  margin-top: 20px;
}
.ads.close.active {
  display: none;
}
.shape-ads {
  position: absolute;
  top: 0;
  right: 0;
}
.shape-ads1 {
  position: absolute;
  top: 0;
  right: 0;
}
.adslogo {
  position: absolute;
  z-index: 11;
}
.adslogo img {
  width: 91px;
}
.adsimg {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 22;
}
.ads-inner button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 222;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: none;
  border: 1px solid #3577f0;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 1em;
  height: 11px;
  overflow: hidden;
  position: static;
  width: 11px;
  color: #fff;
}
input[type="checkbox"]:hover {
  background: #3577f0;
  box-shadow: inset 0 0 0 2px;
}
.xmark {
  position: absolute;
  top: 12px;
  right: 19px;
  z-index: 222;
  width: 20px;
  height: 20px;
  background: #fff;
  text-align: center;
  line-height: 25px;
  border-radius: 500px;
  cursor: pointer;
}
.xmark svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  color: #3577f0;
}
/* responsive */
@media only screen and (min-width: 1399.98px) {
  .adslogo img {
    width: 135px;
  }
}
@media (max-width: 991.98px) {
  .ads {
    margin-bottom: 37px;
  }
}
@media (max-width: 575.98px) {
  .shoting-left p {
    font-size: 14px;
  }
  .shoting-right span {
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  .prodict-shoting {
    display: block;
  }
  .shoting-left {
    margin-bottom: 10px;
  }
}
