.single-cetagoris {
	border-radius: 5px;
	background: #bbc8ff33;
	text-align: center;
	padding: 26px 0px;
	position: relative;
	padding-bottom: 47px;
}
.cetagoris-title {
  width: 100%;
  border-radius: 500px;
  border: 1px solid #e4f2ff;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -31px;
  left: 0;
  padding: 10px 0;
}
.cetagoris-title h5 {
  font-size: 18px;
  font-weight: 400;
  color: #13172b;
  line-height: 22px;
  margin-bottom: 0;
}
.cetagoris-title span {
  color: #5a5858;
  font-size: 14px;
  font-weight: 400;
}
.cetagoris-img img{
  transition: all .4s ease-in-out;
}
.single-cetagoris:hover .cetagoris-img img{
  transform: scale(1.1);
}
@media (max-width: 767.98px) {
  .cetagoris-title h5 {
    font-size: 16px;
  }
  .cetagoris-title span {
    font-size: 13px;
  }
}
