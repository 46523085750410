.cart-slide .cart-item .item-quantity {
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 130px;
  border-radius: 50px;
  width: 120px;
  height: 40px;
  background: #ffffff;
}
.item-quantity .quantity-details {
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  width: 130px;
  height: 42px;
  border-radius: 50px;
  width: 120px;
  border: 1px solid #d5d5d5;
}
.details-button {
  margin: 0px 5px;
}
.item-quantity.quantity-details button {
  color: #5a5858;
}
.item-quantity.plusmainus-quantity button svg {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
}
.item-quantity.plusmainus-quantity button {
  height: 32px;
  width: 32px;
  line-height: 32px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.item-quantity.plusmainus-quantity button:hover {
  border-color:#ff497c;
}
.item-quantity h3 {
  font-size: 16px;
  margin: 0;
  line-height: 32px;
  height: 35px;
  width: 35px;
  font-weight: 500;
  color: #5a5858;
  text-align: center;
}
.table tbody tr td .item-quantity.quantity-details {
  display: flex;
  background: #ffff;
  justify-content: center;
  border-radius: 50px;
}
@media (max-width: 576px) {
  .item-quantity.plusmainus-quantity button svg {
    font-size: 14px;
  }
  .item-quantity h3 {
    font-size: 14px;
  }
}
@media (max-width: 420px) {
  .item-quantity .quantity-details {
    height: 36px;
    width: 88px;
  }
}
