.hero-area {
  overflow: hidden;
}
.main-color {
	background: #eafaea;
	border-radius: 10px;
	position: relative;
}
.hero-wraper {
	padding-top: 60px;
	z-index: 9;
	position: relative;
}
.hero-left img {
  width: 25%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.shape2 {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 112px;
  animation: updown 10s linear infinite;
}
@keyframes updown {
  0%{
    bottom: 50px;
  }
  50%{
    bottom: 0;
  }
  100%{
    bottom: 50px;
  }
}
.shape3 {
	position: absolute;
	right: 0;
	top: 0;
	width: 128px;
  animation: leftright 10s linear infinite;
}
@keyframes leftright {
  0%{
    right: 50px;
  }
  50%{
    right: 0;
  }
  100%{
    right: 50px;
  }
}
.hero-content-right {
	position: relative;
}
.quin {
	position: absolute;
	top: -31px;
	left: 74%;
  animation: top 10s linear infinite;
}
@keyframes top {
  0%{
    top: -90px;
  }
  50%{
    top: -50px;
  }
  100%{
    top: -90px;
  }
}
.hero-title h2 {
  font-size: 60px;
  line-height: 80px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 37px;
}
.clinents-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #f5f5f5;
  position: relative;
  padding: 30px 23px 55px 42px;
  position: relative;
  text-align: center;
}
.hero-clinets-img {
  position: relative;
  margin-top: 121px;
}
.clinents-img img {
  margin-left: -22px;
  width: 52px;
}
.client-rating {
  background: #ffff;
  width: 85%;
  padding: 8px 10px;
  border-radius: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  position: absolute;
  bottom: -34px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.rating-star span {
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5a5858;
}
.rating-star svg {
  color: #fcc013;
}
.trust-cliend h3 {
  font-size: 25px;
  line-height: 23px;
  color: #13172b;
  font-weight: 500;
  margin-bottom: 0;
}
.trust-cliend span {
  font-size: 14px;
  color: #5a5858;
  font-weight: 400;
  line-height: 23px;
}
.main-img img {
  width: 100%;
  margin-bottom: -23px;
}
.content img {
  width: 100%;
}

@media (max-width: 1199.98px) {
  .clinents-img img {
    margin-left: -24px;
    width: 47px;
  }
  .rating-star svg {
    font-size: 10px;
  }
  .rating-star span {
    font-size: 10px;
  }
  .trust-cliend h3 {
    font-size: 18px;
  }
  .trust-cliend span {
    font-size: 10px;
  }
  .hero-title h2 {
    font-size: 48px;
    line-height: 65px;
  }
  .main-color {
    height: inherit;
  }
}
@media (max-width: 991.98px) {
  .hero-title h2 {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 20px;
  }
  .hero-wraper {
    padding-top: 40px;
  }
  .hero-left img {
    width: 100%;
    position: inherit;
  }
}
@media (max-width: 767.98px) {
  .hero-clinets-img {
    margin-top: 27px;
    margin-bottom: 65px;
  }
  .clinents-img img {
    margin-left: -11px;
    width: 43px;
  }
  .hero-title {
    text-align: center;
  }
  .main-img {
    margin-top: 30px;
  }
}
@media (max-width: 576.98px) {
  .hero-title h2 {
    font-size: 21px;
    line-height: 36px;
  }
  .rating-star svg {
    font-size: 14px;
  }
  .rating-star span {
    font-size: 14px;
  }
  .trust-cliend h3 {
    font-size: 26px;
  }
  .trust-cliend span {
    font-size: 14px;
  }
  .main-color {
    border-radius: 0;
    margin: 0;
  }
  .hero-breadcrumb-area {
    padding-top: 50px;
  }
}
