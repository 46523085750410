.embroidered-left-img .embroidered-big-img {
  background: #f5f5f5;
  text-align: center;
  padding: 50px 0px;
  border-radius: 5px;
}
.embroidered-small-img {
  margin-top: 20px;
}
.embroidered-small-img .nav-item button {
  width: 100%;
}
.embroidered-small-img button {
  padding: 0px;
}
.embroidered-small-img img {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
}
@media (max-width: 420px) {
  .embroidered-big-img img {
    width: 100%;
  }
  .embroidered-left-img {
    margin-right: 0px;
  }
}
