.header-info ul li {
  display: inline-block;
}
.header-info-right {
  display: flex;
  justify-items: center;
  justify-content: flex-end;
}
.header-info ul li a,
span {
  font-size: 14px;
  color: #c8c2c2;
  font-weight: 400;
  line-height: 20.23px;
  transition: all 0.3s ease;
}
.header-info ul li a:hover {
  color: #ff497c;
}
.header-info ul li p {
  font-size: 14px;
  color: #c8c2c2;
  font-weight: 400;
  line-height: 20.23px;
}
.header-info.select-wrap.order-tracking {
  margin: 0;
}
.header-info ul li:first-child {
  margin-right: 60px;
  position: relative;
}
.header-info ul li:first-child::before {
  position: absolute;
  content: "";
  height: 22px;
  width: 1px;
  background: #c8c2c2;
  right: -30px;
  top: 0;
}
.header-info ul li span {
  color: #ff497c;
}
.select-wrap.order-tracking {
  margin-left: 60px;
}
.select-wrap {
  margin-left: 60px;
}
.header-info.select-wrap.order-tracking ul li {
  margin: 0;
}
.select-wrap ul li span {
  cursor: pointer;
}
.select-wrap.langguage {
  position: relative;
}
.select-wrap.langguage::after {
  position: absolute;
  content: "";
  height: 22px;
  width: 1px;
  background: #c8c2c2;
  left: -30px;
  top: 0;
}
.select-wrap ul li svg {
  color: #c8c2c2;
  font-size: 11px;
}
.select-wrap ul {
  position: relative;
}
.select-wrap ul li ul {
  position: absolute;
  min-width: 150px;
  background: #fff;
  padding: 12px 20px;
  visibility: hidden;
  box-shadow: -2px 2px 81px -27px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  margin-top: 45px;
  border-radius: 5px;
  z-index: 999;
  top: 100%;
  left: -63px;
}
.select-wrap ul li ul li {
  padding: 4px 0px;
}
.select-wrap ul li:hover ul {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
}
.select-wrap ul li ul li a {
  color: #5a5858;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  transition: all 0.3s ease;
}
.select-wrap ul li ul li a:hover {
  color: #ff497c;
}
/* resposive */
@media (max-width: 1199.98px) {
  .header-info {
    text-align: center;
  }
  .header-info-right {
    justify-content: center;
  }
}
@media (max-width: 991.98px) {
  .header1-area {
    padding: 20px 15px;
  }
}
@media (max-width: 575.98px) {
  .header-info ul li:first-child {
    margin-right: 40px;
  }
  .header-info ul li:first-child::before {
    right: -24px;
  }
  .select-wrap ul li ul {
    left: -56px;
  }
  .header-info {
    margin-bottom: 10px;
  }
  .header-info ul li a {
    font-size: 12px;
  }
  .header-info ul li p {
    font-size: 12px;
  }
  .select-wrap ul li span {
    font-size: 12px;
  }
}
@media (max-width: 420px) {
  .header-info {
    margin-bottom: 7px;
  }
  .header-info ul li:first-child {
    margin-right: 38px;
  }
  .header-info ul li a {
    font-size: 10px;
  }
  .header-info ul li p {
    font-size: 10px;
  }
  .select-wrap.order-tracking {
    margin-left: 28px;
  }
  .select-wrap ul li span {
    font-size: 10px;
  }
  .header-info.select-wrap.order-tracking {
    margin-right: 22px;
  }
  .select-wrap ul li ul {
    left: -77px;
  }
  .header-info ul li span {
    font-size: 10px;
  }
}
