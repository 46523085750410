.table thead tr th {
  padding: 23px 44px;
  border: none;
  box-shadow: none;
  background: #f5f5f5;
  color: #13172b;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}
.table thead tr th:first-child {
  padding: 23px 38px;
  border-radius: 5px 0px 0px 5px;
}
.table thead tr th:last-child {
  padding: 23px 38px;
  border-radius: 0px 5px 5px 0px;
}
.product-cart-list-area .table tbody tr td {
  margin-top: 8px;
  padding: 33px 49px;
  color: #5a5858;
  font-size: 16px;
  background: none;
  border: none;
  line-height: 37px;
  font-weight: 400;
}
.product-cart-list-area .table tbody tr td span{
  color: #353434;
}
.cart-listimg img {
  background: #f5f5f5;
}
.table tbody tr td span svg {
  margin-left: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.table tbody tr td span svg:hover {
  color: #ff497c;
}
.table > :not(caption) > * > * {
  padding: 10px 34px;
  color: inherit;
  border: none;
  background-color: inherit;
  box-shadow: none;
}
.cart-listimg span {
  margin-left: 10px;
  font-size: 16px;
  line-height: 26px;
  color: #353434;
  font-weight: 400;
}
.in-stock a {
  border: 1px solid#a09a9a;
  background: none;
  padding: 10px 20px;
  border-radius: 500px;
  font-size: 14px;
  line-height: 24px;
  color: #13172b;
  font-weight: 400;
  margin-left: 30px;
  transition: all 0.3s ease;
}
.in-stock a:hover {
  background: #ff497c;
  border: 1px solid transparent;
  color: #ffffff;
}
