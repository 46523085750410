.hero-breadcrumb-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.herosectionv2 {
  padding-top: 164px;
}
.herosectionv2-img {
  position: relative;
}
.mains-img {
  position: relative;
}
.shape {
  position: absolute;
  left: 4%;
  bottom: 50%;
}
.shape img {
  width: 170px;
  animation: mymove 3s infinite;
}
@keyframes mymove{
  0% {
    transform: scale(0.8);
  }

  50% {
      transform: scale(1);
  }
  100% {
      transform: scale(0.8);
  }
}
.herosectionv2-img.float-right .shape {
  right: 18px;
  bottom: 52%;
}
.herosectionv2-img.float-right {
  text-align: right;
}
.herosectionv2-content h3 {
  font-size: 30px;
  line-height: 30px;
  color: #ffff;
  font-weight: 600;
}
.herosectionv2-content ul li {
  font-size: 16px;
  line-height: 26px;
  display: inline-block;
  color: #ff497c;
  position: relative;
  font-weight: 600;
}
.herosectionv2-content ul li a {
  color: #ffff;
  margin-right: 30px;
  position: relative;
}
.herosectionv2-content ul li::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #3577f0;
  top: 9px;
  border-radius: 50%;
  right: 10px;
}
.herosectionv2-content ul li:last-child::before {
  display: none;
}
@media (max-width: 991.98px) {
  .herosectionv2 {
    padding-top: 220px;
  }
  .herosectionv2-img.float-right .shape {
    right: 0;
  }
  .mains-img img {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .shape img {
    width: 114px;
  }
  .herosectionv2-content h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .herosectionv2-content ul li a {
    font-size: 11px;
  }
  .herosectionv2-content ul li {
    font-size: 11px;
  }
  .herosectionv2-content ul li a {
    margin-right: 23px;
  }
  .herosectionv2-content ul li::before {
    right: 8px;
  }
}
@media (max-width: 420px) {
  .herosectionv2-content ul li a {
    font-size: 8px;
  }
  .herosectionv2-content ul li {
    font-size: 8px;
  }
  .shape img {
    width: 77px;
  }
  .herosectionv2-content h3 {
    font-size: 15px;
  }
  .herosectionv2-content ul li a {
    margin-right: 14px;
  }
  .herosectionv2-content ul li {
    display: inline-block;
  }
  .herosectionv2-content ul li::before {
    height: 5px;
    width: 5px;
    top: 11px;
    right: 4px;
  }
}
