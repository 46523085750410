.deal-product-timer {
  display: flex;
  width: 240px;
  height: 50px;
  text-align: center;
  background: #fff;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.single-timer {
  width: 50px;
}
.single-timer span {
  color: #5a5858;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  display: block;
}
.single-timer span.time {
  color: #000000;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 8px;
}
.offer-end span {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #5a5858;
}
/* deal-of-weak-area */
.deal-product-timer.deal-countdown .single-timer {
  height: 100px;
  width: 100px;
  margin-right: 10px;
  background: #ffffff;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 8px 8px 15px 0px #f01e1e12;
  border-radius: 500px;
  border-right: 3px solid#ff497c;
}
.deal-product-timer.deal-countdown {
  display: flex;
  width: auto;
  height: auto;
  float: none;
  align-items: center;
  text-align: center;
  background: none;
  border-radius: inherit;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 50px;
  justify-content: flex-start;
}
.deal-product-timer.deal-countdown .single-timer span.time {
  color: #ff497c;
  font-size: 35px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 5px;
}
.deal-product-timer.deal-countdown .single-timer span {
  color: #5a5858;
  font-size: 16px;
  line-height: 30px;
  line-height: 16px;
  font-weight: 400;
  display: block;
}
@media (max-width: 1199.98px) {
  .deal-product-timer {
    width: 218px;
  }
}
@media (max-width: 991.98px) {
  .deal-product-timer.deal-countdown .single-timer span.time {
    font-size: 24px;
    line-height: 25px;
    font-weight: 500;
  }
  .deal-product-timer.deal-countdown .single-timer span {
    font-size: 14px;
    line-height: 14px;
  }
  .deal-product-timer.deal-countdown .single-timer {
    height: 70px;
    width: 70px;
  }
}
@media (max-width: 420px) {
  .deal-product-timer.deal-countdown .single-timer span {
    font-size: 10px;
  }
}
