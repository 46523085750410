.fewture-content {
  text-align: center;
  padding: 32px;
  padding-bottom: 25px;
  box-shadow: 0px 2px 1.9px 0.1px rgba(0,0,0,.05);
  transition: all .4s ease;
  border: 1px solid transparent;
  border-radius: 5px;
}
.fewture-content:hover{
  box-shadow: none;
  border-color: #ff497c;
}
.fewture-img {
  margin-bottom: 15px;
}
.f-content h3 {
  font-size: 20px;
  font-weight: 600;
  color: #111111;
  line-height: 20px;
}
.f-content p {
  color: #5a5858;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 1199.98px) {
  .f-content h3 {
    font-size: 16px;
  }
}
@media (max-width: 420px) {
  .fewture-img img {
    width: 46px;
  }
}
