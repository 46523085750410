.table-thead {
  border-radius: 5px;
  background: #e4f2ff;
  padding: 23px 34px;
}
.table-thead h3 {
  font-size: 20px;
  color: #13172b;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}
.cart-list {
  background: #ffecf1;
  padding: 20px 33px;
  border-radius: 5px;
}
@media (max-width: 1199.98px) {
  .table > :not(caption) > * > * {
    padding: 10px 32px;
  }
  .cart-listimg span {
    font-size: 14px;
  }
  .product-cart-list-area .table tbody tr td {
    padding: 33px 35px;
  }
  .table thead tr th {
    padding: 23px 28px;
  }
}
@media (max-width: 991.98px) {
  .table {
    width: 932px;
  }
}
