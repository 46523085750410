/*
Theme Name: Shopx React eCommerce Template
Support: rana271199@gmail.com
Description: Shopx React eCommerce Template
Version: 1.0
*/

/* google-fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400;1,500&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  color: #13172b;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
a,
.button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
button {
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
}
a:hover {
  color: #ff497c;
}
a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}
a,
button {
  outline: medium none;
  border-radius: 0;
  border: none;
}
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #13172b;
  margin-top: 0px;
  font-style: normal;
  font-weight: 700;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 60px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 38px;
}
h4 {
  font-size: 28px;
  color: #13172b;
}
h5 {
  font-size: 24px;
}
h6 {
  font-size: 18px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #6c6c6c;
  margin-bottom: 0px;
  text-transform: capitalize;
}
label {
  color: #6c6c6c;
  font-size: 14px;
  font-weight: 400;
}
*::-moz-selection {
  background: #ff497c;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #ff497c;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #ff497c;
  color: #fff;
  text-shadow: none;
}
*::-moz-placeholder {
  color: #6b6767;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
*::placeholder {
  color: #6b6767;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
/* default-button */
.button-c {
  padding: 8px 23px;
  border-radius: 50px;
  border: 1px solid #ff497c;
  color: #13172b;
  background: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-transform: capitalize;
  transition: 0.3s;
}
.section-bg-v1 {
  background: #f5f5f5;
}
/* default-margin */
.mb-15 {
  margin-bottom: 15px;
}
.mb-50{
  margin-bottom: 50px;
}
.c-relative{
  position: relative;
}
.c-absulate{
  position: absolute;
}
.c-overfallow{
  overflow: hidden;
}
.ovr-plr{
  padding: 0 70px;
}
.mb-20 {
  margin-bottom: 20px;
}
/* default-padding */
.pt-70 {
  padding-top: 70px;
}
.pt-50 {
  padding-top: 50px;
}
.section-ptb {
  padding-top: 70px;
  padding-bottom: 70px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pr-0 {
  padding-right: 0px;
}
.pl-0 {
  padding-left: 0px;
}
@media (max-width: 1399.98px) {
  .ovr-plr{
    padding: 0 15px;
  }
}
@media (max-width: 991.98px) {
  .md-mb-30 {
    margin-bottom: 30px;
  }
  .header1-area {
    padding: 20px 15px;
  }
}
@media (max-width: 767.98px) {
  .pl-12 {
    padding-left: 12px;
  }
  .pr-12 {
    padding-right: 12px;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .header1-area {
    padding: 20px 15px;
  }
}
