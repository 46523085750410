.single-cetagoris-v1 {
  width: 100%;
  background: #f5f5f5;
  margin-top: 20px;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px 12px 20px;
  transition: all 0.3s ease;
}
.cetagoris-v1 {
  background: #ffffff;
  padding: 13px;
  width: 40%;
  margin-top: -19px;
  margin-left: -10px;
  border: 1px solid #f9f3f0;
  border-radius: 4px;
  height: auto;
}
.cetagoris-title-v1 h5 {
  color: #13172b;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0;
  transition: all 0.3s ease;
}
.single-cetagoris-v1:hover .cetagoris-title-v1 h5 {
  color: #ff497c;
}
.cetagoris-title-v1 span {
  font-size: 14px;
  color: #5a5858;
  line-height: 24px;
  font-weight: 400;
}
.cetagoris-v1 img {
  width: 100%;
}
/* work-procesing-area */
.single-cetagoris-v1.work-procesing {
  width: 100%;
  background: #ffffff;
  margin-top: 10px;
  height: inherit;
  padding: 12px 12px 12px 20px;
  box-shadow: 0px 0px 20px 0px #00000014;
}
.single-cetagoris-v1.work-procesing .cetagoris-title-v1 {
  margin-right: 30px;
}
.single-cetagoris-v1.work-procesing .cetagoris-v1 {
  background: #ff497c;
  padding: 10px;
  width: 130px;
  position: relative;
  margin-top: -25px;
}
.single-cetagoris-v1.work-procesing .cetagoris-v1 a {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 12px;
  text-align: center;
  line-height: 14px;
  color: #100f0f;
  background: #fff;
  border-radius: 10px;
  margin: 0;
  bottom: -33px;
  padding: 4px 5px;
  box-shadow: 0px 0px 20px 0px #00000014;
}
.single-cetagoris-v1.work-procesing .cetagoris-title-v1 h5 {
  margin-bottom: 10px;
}
/* responsive */
@media (max-width: 991.98px) {
  .single-cetagoris-v1.work-procesing .cetagoris-v1 {
    margin-top: -20px;
  }
  .single-cetagoris-v1.work-procesing {
    padding: 32px 12px 38px 26px;
  }
}
