.total-cetagoris {
  position: relative;
}
.total-cetagoris
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding-bottom: 20px;
}
.categories-slider-v1 .next-prev {
  position: absolute;
  right: 118px;
  top: 0;
  left: inherit;
  bottom: 83px;
}
.categories-slider-v1 .button-next-slide1 {
  margin-left: 70px;
}
@media (max-width: 420px) {
  .categories-slider-v1 .next-prev {
    right: 94px;
    top: -13px;
  }
  .categories-slider-v1 .next-prev div {
    width: 35px;
    height: 35px;
  }
  .categories-slider-v1 .button-next-slide1 {
    margin-left: 51px;
  }
  .categories-slider-v1 .next-prev div svg {
    font-size: 15px;
  }
}
