.embroidered-content h2 {
  font-size: 29px;
  color: #13172b;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 15px;
}
.product-price {
  display: flex;
  margin-bottom: 2px;
}
.old-price {
  margin-right: 10px;
}
.old-price del {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #5a5858;
}
.new-price span {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #5a5858;
}
.item-rating {
  margin-bottom: 30px;
  border-bottom: 1px solid#E4F2FF;
  padding-bottom: 30px;
}
.item-rating span svg {
  color: #ffa800;
  font-size: 14px;
}
.item-rating .review {
  font-size: 14px;
  color: #5a5858;
  font-weight: 400;
  line-height: 30px;
  margin-left: 10px;
}
.user-view {
  margin-bottom: 30px;
}
.user-view p {
  line-height: 26px;
  color: #5a5858;
  margin-bottom: 10px;
}
.user-view span {
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #3577f0;
  font-weight: 400;
}
.user-view span img {
  margin-right: 10px;
}
.strock {
  color: #00a01a;
  margin-top: 6px;
}
.color-chose.color-mixer.filter-widget.widget-1.price-filter {
  background: none;
  padding: 0;
  margin-bottom: 20px;
}
.color-chose.color-mixer.filter-widget.widget-1.price-filter h2 {
  font-size: 20px;
  line-height: 20px;
  color: #13172b;
  font-weight: 500;
  margin-bottom: 12px;
}
.product-size {
  margin-bottom: 20px;
}
.product-size h2 {
  font-size: 20px;
  line-height: 20px;
  color: #13172b;
  font-weight: 500;
  margin-bottom: 12px;
}
.product-size ul li {
  display: inline-block;
}
.product-size ul li a {
  border: 1px solid #cccccc;
  color: #696969;
  font-size: 14px;
  line-height: 15px;
  font-weight: 400;
  padding: 9px 13px;
  display: inline-block;
  border-radius: 50px;
  margin-bottom: 4px;
  padding-bottom: 6px;
  margin-right: 3px;
  transition: all 0.3s ease;
}
.product-size ul li a:hover {
  background: #ff497c;
  border: 1px solid#ff497c;
  color: #ffff;
}
.item-addtocart {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid#E4F2FF;
}
.reaction span {
  height: 50px;
  width: 50px;
  border: 1px solid #d5d5d5;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  line-height: 51px;
  border-radius: 500px;
  margin-right: 5px;
  transition: all 0.3s ease;
}
.item-quantity .quantity-details .reaction span svg {
  color: #000000;
  font-size: 30px;
  font-weight: 400;
}
.reaction span:hover {
  background: #ff497c;
  border: 1px solid#ff497c;
  color: #ffff;
}
.category-item {
  margin-bottom: 20px;
}
.category-item p {
  font-size: 14px;
  line-height: 24px;
  color: #13172b;
  font-weight: 400;
}
.item-share {
  display: flex;
}
.item-share h2 {
  font-size: 20px;
  font-weight: 500;
  color: #13172b;
  line-height: 20px;
  margin-bottom: 0;
  margin-right: 15px;
}
.item-share span {
  margin-right: 12px;
  cursor: pointer;
}
.item-share svg {
  font-size: 20px;
}
.item-share .fb svg {
  color: #3e75b2;
}
.item-share .tw svg {
  color: #3fd1ff;
}
.item-share .ps svg {
  color: #e12828;
}
/* responsive */
@media (max-width: 991.98px) {
  .embroidered-content h2 {
    font-size: 25px;
  }
  .embroidered-left-img {
    margin-right: 0px;
  }
  .item-rating {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .item-addtocart {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}
