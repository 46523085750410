.search-form input {
  height: 100%;
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #aeaeae;
  border-radius: 50px;
  transition: 0.3s;
}
.single-input.filter label {
  font-size: 16px;
  color: #696969;
  line-height: 30px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.3s ease;
}
.single-input.filter label:hover {
  color: #ff497c;
}
.single-input label {
  cursor: pointer;
}
/* review-input */
.single-input.review-input input {
  background: #e4f2ff;
  width: 100%;
  border: 1px solid #ffffff;
  height: 50px;
  border-radius: 10px;
  padding: 0px 20px;
  font-size: 13px;
}
.single-input.review-input ::placeholder {
  color: #a4a4a4;
}
/* cart-discounts */
.single-input.discounts input {
  border: none;
  border-bottom: 1px solid #ec4899;
  width: 100%;
  height: 50px;
  padding-right: 130px;
}
.single-input.discounts ::placeholder {
  color: #a4a4a4;
}
.single-input.radio-btn input {
  width: 14px;
  height: 14px;
  accent-color: #ec4899;
}
.single-input.zip input {
  width: 100%;
  border: 1px solid #e4f2ff;
  border-radius: 10px;
  height: 48px;
  padding: 0px 20px;
}
.single-input.zip {
  margin-bottom: 10px;
}
.single-input.zip ::placeholder {
  color: #a4a4a4;
}
/* billing-input */
.single-input.billing-input input {
  background: #f5f5f5;
  border: none;
  width: 100%;
  height: 50px;
  padding: 0px 15px;
  border-radius: 5px;
}
.single-input.billing-input ::placeholder {
  color: #a4a4a4;
}
.single-input.billing-input-checkbox {
  display: flex;
  align-items: center;
}
.single-input.billing-input-checkbox label {
  line-height: 24px;
  margin-left: 12px;
}
.single-input.radio-btn.cash-on-single {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.single-input.radio-btn.cash-on-single:last-child {
  margin: 0;
}
.single-input.radio-btn.cash-on-single input {
  margin: 0;
  margin-right: 10px;
}
/* faq-form */
.single-input.faq-form input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: none;
  padding: 0px 20px;
}
.single-input.faq-form ::placeholder {
  color: #a4a4a4;
}
/* blog-details */
.single-input.blog-dt.faq-form input {
  background: #f9f3f0;
}
.single-input.blog-massage.faq-massage textarea {
  background: #f9f3f0;
}
.button.blog-dt-btn.faq-btn {
  width: 218px;
}
.single-input.search-input.faq-form input {
  padding-right: 50px;
}
.single-input.blog-newslatter-input.faq-form input {
  background: #ffffffe5;
}
@media (max-width: 420px) {
  .search-form input {
    padding: 10px 20px;
  }
}
