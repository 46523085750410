.header-top {
	background: #13172b;
	padding-bottom: 10px;
	padding-top: 10px;
}
.bg-color {
  background: #fff;
  margin: 0;
  border-radius: 3px;
}
@media (max-width: 1199.98px) {
  .header-right ul li {
    margin-left: 22px;
  }
}
@media (max-width: 991.98px) {
  .bg-color {
    padding: 18px 0px;
  }
}
@media (max-width: 420px) {
  .home1-header .header-right {
    margin-right: 5px;
  }
  .header-right ul li {
    margin-left: 10px;
  }
  .header-right ul li svg {
    margin-bottom: 0px;
  }
  .menu-icon svg {
    margin-bottom: 0px;
  }
  .header1-area .logo a img {
    top: -15px;
  }
  .bg-color {
    padding: 10px 0px;
  }
}
