.testimonial-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.testimonial-wraper {
  text-align: center;
}
.testimonial-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
}
.testimonial-icon {
  margin-right: 20px;
}
.testimonial-icon svg {
  font-size: 40px;
  color: #ff497c;
}
.testimonial-rating svg {
  color: #fcc013;
  margin-left: 3px;
}
.testimonial-content {
  margin-bottom: 30px;
}
.testimonial-content p {
  font-size: 16px;
  line-height: 26px;
  color: #6c6c6c;
  font-weight: 400;
  text-transform: none;
}
.testimonial-author {
  display: flex;
  align-items: center;
  justify-content: center;
}
.author-identy {
  margin-left: 20px;
  text-align: left;
}
.author-identy h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 35px;
  color: #101010;
  margin-bottom: 0;
}
.author-identy h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #2639ff;
  margin-bottom: 0;
}
.section-bg {
  background: #F5F5F5;
  margin: 0px 20px;
  border-radius: 10px;
}
/* testimonialv1-wraper */
.testimonial-wraper.testimonialv1-wraper {
  padding: 30px;
  border-radius: 5px;
  background: #ffffff;
}
.testimonial-wraper.testimonialv1-wraper .testimonial-rating {
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px #0000000f;
  border-radius: 500px;
  padding: 5px 9px;
}
.testimonial-wraper.testimonialv1-wraper .testimonial-content p {
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
  font-weight: 400;
  padding-left: 0px;
  border-left: none;
}
.testimonial-wraper.testimonialv1-wraper .author-identy h2 {
  font-size: 20px;
  line-height: 30px;
  color: #13172b;
}
.testimonial-wraper.testimonialv1-wraper .author-identy h6 {
  font-size: 14px;
  line-height: 24px;
  color: #5a5858;
}
.testimonial-wraper.testimonialv1-wraper .testimonial-author {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 10px 15px;
  border-radius: 5px;
}
@media (max-width: 420px) {
  .testimonial-wraper.testimonialv1-wraper .author-identy h2 {
    font-size: 18px;
  }
  .author-identy h2 {
    font-size: 20px;
  }
  .section-bg {
    margin: 0px;
  }
}
