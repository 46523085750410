.button {
  padding: 15px 60px;
  border-radius: 15px;
  background: #ff497c;
  border: 1px solid #ff497c;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
  transition: 0.3s;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
}
.button:hover {
  background: none;
  color: #13172b;
}
.button2 {
  background: none;
  color: #13172b;
}
.button2:hover {
  background: #ff497c;
  color: #fff;
}
.button.blog-btn {
  padding: 10px 30px;
}
.button.button.shop-btn.blog-btn {
  background: #ffffff;
  color: #13172b;
  padding: 6px 26px;
  font-size: 14px;
  border: 1px solid transparent;
  transition: all.3s ease;
}
.button.button.shop-btn.blog-btn:hover {
  border: 1px solid#ff497c;
}
.button.quantity-btn {
  padding: 13px 44px;
  font-size: 18px;
  line-height: 18px;
}
/* discounts-btn */
.button.discounts-btn {
  position: absolute;
  top: 3px;
  font-size: 16px;
  padding: 6px 31px;
  right: 4px;
  border-radius: 3px;
}
.button.update-btn {
  width: 100%;
  height: 48px;
  line-height: 17px;
  background: #f5f5f5;
  border: none;
  font-size: 16px;
  color: #181818;
}
/* faq-btn */
.button.faq-btn {
  width: 146px;
  margin-top: 20px;
  height: 50px;
  font-size: 16px;
  line-height: 18px;
  padding: 15px 12px;
}
/* blog-details-btn */
.button.search-button {
  background: no-repeat;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 9px;
  right: 18px;
  color: #ff497c;
}
.button.blog-newslatter-btn {
  width: 100%;
  padding: 10px 37px;
  font-size: 18px;
  margin-top: 10px;
}
.button.blog-newslatter-btn:hover {
  color: #ffff;
}
/* responsive */
@media (max-width: 991.98px) {
  .button {
    padding: 6px 25px;
    font-size: 15px;
  }
}

@media (max-width: 767.98px) {
  .button.quantity-btn {
    padding: 13px 24px;
  }
}
@media (max-width: 420px) {
  .button.quantity-btn {
    padding: 9px 10px;
    font-size: 13px;
  }
}
