.promo-1 {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  background-position: center;
  position: relative;
  background-size: cover;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-color: #bbc8ff33;
  overflow: hidden;
}
.promo-1 .mains-img {
  position: absolute;
  right: 2px;
  bottom: 0;
}
.promo-1 .promo-title {
  padding: 32px 0 0 18px;
}
.promo-title h6 {
  color: #5a5858;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
}
.promo-title h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  margin-bottom: 55px;
}
.promo-card:hover .promo-title h3{
  color: #ffff;
}
.promo-1 .promo-discount span {
  color: #ff497c;
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
  padding: 11px 22px 9px 44px;
  display: inline-block;
}
@media (max-width: 1399px) {
  .promo-title {
    width: 57%;
  }
}
@media (max-width: 1199.98px) {
  .promo-1 .mains-img {
    z-index: 1;
  }
  .promo-1 .promo-discount span {
    font-size: 19px;
    line-height: 19px;
  }
}
@media (max-width: 991.98px) {
  .promo-title h6 {
    font-size: 15px;
    margin-bottom: 13px;
  }
  .promo-title h3 {
    font-size: 15px;
  }
  .promo-1 .promo-discount span {
    font-size: 21px;
    line-height: 22px;
  }
}
@media (max-width: 420px) {
  .promo-title h6 {
    margin-bottom: 4px;
  }
  .promo-title h6.discountv1 {
    margin-bottom: 15px;
  }
  .promo-title h3 {
    margin-bottom: 24px;
  }
}
