.section-title span {
  color: #ff497c;
  font-family: Josefin Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-block;
}
.section-title h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.07;
  text-transform: capitalize;
}
@media (max-width: 991.98px) {
  .section-title {
    margin-bottom: 20px;
  }
  .section-title h2 {
    margin-bottom: 0px;
  }
}
