.discription-reviews-tabs .nav-tabs {
  border: none;
}
.discription-reviews-tabs .nav-tabs .nav-link.active {
  background: #ff497c;
  color: #ffffff;
  border-bottom: 0;
  overflow: hidden;
  margin-bottom: 0;
  height: 100%;
  font-size: 18px;
  line-height: 18px;
}
.discription-reviews-tabs .nav-tabs .nav-link {
  color: #ffffff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  margin: 0;
  border: 1px solid #e4f2ff;
  padding: 15px 25px;
  border-bottom: 0;
}
.discription-reviews-tabs .nav-tabs .nav-link {
  color: #111111;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  border: 1px solid #e4f2ff;
  border-bottom: 0;
  padding: 15px 25px;
  margin-right: 7px;
}

.discription-tablist {
  padding: 20px;
  border: 1px solid#e4f2ff;
  border-radius: 0px 5px 5px 5px;
}
.discription-reviews-tabs .single-review {
  border: none;
  border-radius: 0px;
  padding: 0px;
}
.discription-reviews-tabs .review {
  margin-left: 0px;
}
.discription h2 {
  font-size: 26px;
  line-height: 35px;
  font-weight: 600;
  color: #13172b;
  margin-bottom: 15px;
}
.discription p {
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
  text-transform: none;
  margin-bottom: 20px;
}
.discription h4 {
  font-size: 20px;
  line-height: 20px;
  color: #13172b;
  font-weight: 400;
  margin-bottom: 10px;
}
.discription ul li {
  font-size: 16px;
  color: #5a5858;
  line-height: 36px;
  font-weight: 400;
  margin-left: 20px;
  position: relative;
}
.discription ul li:before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  background: #181818;
  top: 13px;
  left: -20px;
  border-radius: 50%;
}
.embroidered-left-img {
  margin-right: 15px;
}
.reviews-form {
  border-top: 2px solid #e4f2ff;
  padding: 20px;
  border-radius: 5px;
}
.reviews-form h2 {
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 20px;
  color: #13172b;
  font-weight: 600;
}
.form-rating-reviews span {
  color: #5a5858;
  font-size: 14px;
  line-height: 24px;
}
.form-rating-reviews span svg {
  color: #c1c1c1;
}
.review {
  margin-left: 7px;
}
.review-form-here {
  margin-top: 15px;
}
