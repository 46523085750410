.testimonial-slider .swiper-pagination-horizontal {
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  display: inline-block;
  width: inherit;
}
.testimonial-slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #ff497c;
  border-radius: 500px;
  cursor: pointer;
  display: inline-block;
  height: 6px;
  margin: 0 6px;
  position: relative;
  transition: all 0.3s ease;
  width: 16px;
  opacity: 0.5;
}
.testimonial-slider
  .swiper-pagination-horizontal
  .swiper-pagination-bullet-active {
  background: #ff497c;
  width: 46px;
  height: 6px;
  opacity: 1;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding: 0px 7px;
  padding-bottom: 45px;
}
@media (max-width: 420px) {
  .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    padding: 0px;
    padding-bottom: 19px;
  }
}
