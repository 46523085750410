.blog-left-title h2 {
  font-size: 30px;
  line-height: 30px;
  color: #111111;
  font-weight: 600;
  margin-bottom: 20px;
}
.blogs-detais-meta {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.blog-meta-single {
  margin-right: 40px;
}
.blog-meta-single:last-child {
  margin: 0px;
}
.blog-meta-single svg {
  color: #ff497c;
}
.blog-meta-single span {
  font-size: 14px;
  color: #5a5858;
  line-height: 24px;
  font-weight: 400;
  margin-left: 4px;
}
.blog-details-content p {
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
  text-transform: inherit;
  font-weight: 400;
  margin-bottom: 20px;
}
.blog-big-img img {
  width: 100%;
}
.blog-details-content .blog-bottom-content {
  color: #696969;
  margin-top: 20px;
}
.blog-details-qustion {
  margin-bottom: 20px;
}
.blog-details-qustion ul li {
  font-size: 18px;
  color: #13172b;
  line-height: 26px;
  margin-left: 20px;
  margin-bottom: 12px;
  position: relative;
}
.blog-details-qustion ul li:last-child {
  margin-bottom: 0px;
}
.blog-details-qustion ul li::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #13172b;
  border-radius: 50%;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-testimonial {
  padding: 20px;
  background: #f9f3f0;
  border-radius: 5px;
  margin-bottom: 20px;
}
.blog-testimonial-content p {
  font-size: 26px;
  line-height: 38px;
  color: #111111;
  font-weight: 400;
  margin-bottom: 20px;
}
.blog-testimonial-content p {
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  color: #333333;
  font-weight: 400;
  font-style: italic;
}
.blog-testimonial-content span {
  color: #ff497c;
}
.victor-img {
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.victor-img img {
  margin-right: 10px;
  width: 100%;
}
.victor-img img:last-child {
  margin: 0px;
}
.blog-bottom-img {
  margin-bottom: 20px;
}
.blog-bottom-img img {
  width: 100%;
  border-radius: 10px;
}
.blog-left-tags-social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid#E4F2FF;
  padding: 25px 0px;
  border-bottom: 1px solid#E4F2FF;
}
.blog-left-tags-social .tags {
  display: flex;
  align-items: center;
}
.blog-left-tags-social .tags h6 {
  color: #13172b;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin-right: 20px;
  margin-bottom: 0;
}
.blog-left-tags-social .tags span {
  font-size: 16px;
  line-height: 26px;
  color: #696969;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-right: 20px;
}
.blog-left-tags-social .tags span:last-child {
  margin: 0px;
}
.blog-left-tags-social .tags span:hover {
  color: #ff497c;
}
.blog-details-social {
  display: flex;
  align-items: center;
}
.blog-details-social h6 {
  font-size: 20px;
  line-height: 20px;
  color: #13172b;
  margin-right: 15px;
  margin-bottom: 0px;
}
.blog-details-social a {
  width: 34px;
  height: 34px;
  background: #ff497c;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  margin-left: 5px;
  line-height: 35px;
  box-shadow: 0px 4px 8px 0px #00000029;
}
.blog-details-social svg {
  color: #ffffff;
}
.werite-comment {
  padding: 20px;
  border: 1px solid#E4F2FF;
  border-radius: 5px;
  margin: 65px 0px;
}
.werite-comment h2 {
  font-size: 26px;
  color: #13172b;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 20px;
}
/* blog-right */
.blog-dt-right {
  position: sticky;
  top: 0;
}
.search-form {
  padding: 25px;
  background: #F5F5F5;
  border-radius: 5px;
}
.search-form h2 {
  font-size: 26px;
  line-height: 35px;
  color: #13172b;
  font-weight: 600;
  border-bottom: 1px solid#fff;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.search-form form {
  position: relative;
}
.blog-details-area .blog-dt-right .search-form {
  position: inherit;
}
.latest-post-wraper h2 {
  font-size: 26px;
  line-height: 35px;
  font-weight: 600;
}
.latest-post-img img {
  width: 100%;
  border-radius: 10px;
}
.latest-post-img {
  position: relative;
}
.post-date {
  position: absolute;
  bottom: 9px;
  right: 9px;
  width: 130px;
  height: 30px;
  background: #fff;
  line-height: 30px;
  text-align: center;
  border-radius: 500px;
}
.post-date span {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #5a5858;
}
.post-date svg {
  color: #ff497c;
  font-size: 14px;
  margin-right: 5px;
}
.latest-single p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #13172b;
}
.single-blog-dt-cetagoty {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.single-blog-dt-cetagoty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.single-blog-dt-cetagoty span{
  color: #13172b;
}
.single-blog-dt-cetagoty:last-child {
  margin-bottom: 0px;
}
.popular-tag-dt ul li {
  display: inline-block;
}
.popular-tag-dt ul li a {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #5a5858;
  background: #ffffff;
  padding: 6px 14px;
  display: inline-block;
  border-radius: 11px;
  margin-right: 10px;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}
.popular-tag-dt ul li a:hover {
  color: #ff497c;
}
.blog-newslatter p {
  color: #ffffff;
  line-height: 26px;
  margin-bottom: 40px;
}
.blog-newslatter.search-form {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.blog-newslatter.search-form h2 {
  color: #ffff;
}
@media (max-width: 1199.98px) {
  .victor-img img {
    max-width: 50px;
  }
}
@media (max-width: 991.98px) {
  .blog-dt-right {
    position: inherit;
  }
}
@media (max-width: 767.98px) {
  .blog-left-title h2 {
    font-size: 25px;
  }
  .blog-testimonial-content h4 {
    font-size: 20px;
    line-height: 30px;
  }
  .blog-left-tags-social .tags {
    align-items: flex-start;
    flex-direction: column;
  }
  .victor-img img {
    max-width: 32px;
  }
}
@media (max-width: 420px) {
  .search-form {
    padding: 10px;
  }
  .blog-meta-single {
    margin-right: 10px;
  }
  .blog-meta-single span {
    font-size: 13px;
  }
  .blog-testimonial-content h4 {
    font-size: 14px;
    line-height: 24px;
  }
  .blog-testimonial-content p {
    font-size: 16px;
  }
  .blog-left-tags-social {
    display: block;
  }
  .customer-img {
    margin-right: 10px;
  }
  .inner-pd {
    padding: 10px;
  }
  .customer-img img {
    width: 50px;
  }
  .customer-names h4 {
    font-size: 14px;
    line-height: 14px;
  }
  .customer-reply a {
    padding: 3px 17px;
    font-size: 12px;
  }
  .customer-rating span svg {
    font-size: 12px;
  }
  .victor-img img {
    max-width: 20px;
  }
}
