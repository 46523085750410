.pagenation {
  margin-top: 20px;
}
.pagenation ul li {
  display: inline-block;
  text-align: center;
  margin-right: 7px;
  transition: all 0.3s ease;
}
.pagenation ul li a:hover {
  background: #ff497c;
  color: #ffff;
}
.pagenation ul li a {
  font-size: 14px;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  font-weight: 400;
  color: #5a5858;
  border-radius: 20%;
  line-height: 41px;
  border: 1px solid #ff497c;
}
.pagenation ul li:hover a {
  color: #ffff;
}
.pagenation ul li:hover a svg {
  color: #ffff;
}
.pagenation ul li a svg {
  color: #ff497c;
}
.pagenation ul li a.active {
  background: #ff497c;
}
.pagenation ul li a.active svg {
  color: #ffff;
}
.pagenation ul li.active a svg {
  color: #ffff;
}
.pagenation-dots {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border: 1px solid#555;
}
@media (max-width: 575.98px) {
  .pagenation ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
  }
}
@media (max-width: 420px) {
  .pagenation ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-right: 0px;
    font-size: 13px;
  }
}
