.footer-logo {
  margin-bottom: 20px;
}
.footer-logo img {
  width: 58%;
}
.footer-about-brand p {
  font-size: 16px;
  color: #5a5858;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 26px;
}
.footer-widget .footer-email-contact span {
  display: block;
  font-size: 16px;
  color: #13172b;
  line-height: 35px;
  font-weight: 400;
  margin: 0;
}
.footer-email-contact span a {
  color: #13172b;
  transition: all 0.3s ease;
  margin-left: 8px;
}
.footer-email-contact span a:hover {
  color: #3577f0;
}
.footer-email-contact span svg {
  color: #3577f0;
  font-size: 14px;
}
.footer-widget h2 {
  font-size: 25px;
  color: #13172b;
  line-height: 26px;
  font-weight: 600;
  display: block;
  padding-bottom: 13px;
  overflow: hidden;
  margin-bottom: 0;
}
.footer-widget ul {
  overflow: hidden;
}
.footer-widget ul li {
  margin-top: 10px;
  display: block;
}
.footer-widget ul li svg {
	color: #5a5858;
	font-size: 16px;
	margin-left: -10px;
  opacity: 1;
  transition: all .3s ease-in;
}
.footer-widget ul li:hover svg {
  margin-left: 0;
  color: #3577f0;
}
.footer-widget ul li a {
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
  font-weight: 400;
  margin-bottom: 0;
  transition: all 0.3s ease-in;
  margin-left: 5px;
}
.footer-widget ul li a:hover {
  color: #3577f0;
}
.footer-widget p {
  font-size: 16px;
  color: #5a5858;
  line-height: 26px;
  margin-bottom: 20px;
  margin-top: 10px;
  overflow: hidden;
}
.footer-widget span {
  font-size: 16px;
  color: #5a5858;
  line-height: 20px;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
.download-app a {
  display: block;
  margin-bottom: 10px;
}
.download-app a:last-child {
  margin-bottom: 0px;
}
/* footer-bottom */
.footer-bottom {
  background: #f5f5f5;
  padding: 20px 0px;
}
.footer-bottom-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-social-link {
  margin-bottom: 0px;
}
.footer-social-link ul li {
  display: inline-block;
  width: 32px;
  height: 32px;
  color: #13172b;
  line-height: 35px;
  text-align: center;
  border-radius: 32px;
  background: #fff;
  margin-right: 5px;
}
.footer-social-link ul li a {
  color: #13172b;
  transition: all 0.3s ease;
}
.footer-social-link ul li a:hover {
  color: #ff497c;
}
.copyright {
  margin: 0;
}
.copyright p {
  color: #5a5858;
  line-height: 16px;
}
.payment-options img {
  margin-left: 5px;
}
.footer-widget.footer-widget-1 {
  margin-left: 45px;
}

@media (max-width: 1199.98px) {
  .footer-widget.footer-widget-1 {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .footer-bottom-social {
    display: block;
  }
  .footer-social-link {
    margin-bottom: 20px;
  }
  .copyright {
    margin-bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .footer-logo img {
    width: 39%;
  }
  .footer-widget h2 {
    line-height: 22px;
  }
  .copyright p {
    line-height: 13px;
  }
}
@media (max-width: 420px) {
  .footer-widget h2 {
    font-size: 22px;
  }
  .footer-widget ul li {
    margin-top: 5px;
  }
  .payment-options img {
    width: 54px;
  }
  .copyright p {
    font-size: 15px;
  }
}
