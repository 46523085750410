
.main-menu ul {
  float: right;
}
.main-menu ul li {
  display: inline-block;
  margin-left: 42px;
  position: relative;
  line-height: 0px;
}
.main-menu ul li a {
  color: #13172b;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s ease;
  position: relative;
  padding: 40px 0px;
  font-family: 'Roboto', sans-serif;
}
.main-menu ul li:hover > a {
  color: #3577f0;
}
.main-menu ul li .submenu {
  position: absolute;
  min-width: 280px;
  background: #fff;
  padding: 20px 20px;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  z-index: 9999;
  left: 0;
  box-shadow: 3px 3px 45px 0 rgba(0,0,0,.05);
  transition: all 0.4s ease-in-out;
  border-radius: 5px;
  transform: scaleY(0);
  transform-origin: top;
}
.main-menu ul li .submenu ul {
  float: inherit;
}
.main-menu ul li:hover .submenu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  transform: scaleY(1);
}
.main-menu ul li .submenu ul li {
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  padding: 0;
}
.main-menu ul li .submenu ul li a {
  padding: 10px 0px;
  display: block;
  position: relative;
}
.main-menu ul li .submenu ul li a::before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  top: 50%;
  left: -17px;
  background: #3577f0;
  transition: all .4s ease-in;
}
.main-menu ul li .submenu ul li a:hover::before{
  width: 10px;
}
.main-menu ul li .submenu ul li a:hover{
  padding-left: 15px;
}
.badge {
	position: absolute;
	top: 26px;
	right: -19px;
	transform: translateY(-100%);
	font-weight: 500;
	font-size: 12px;
	color: #ffffff;
	padding: 3px 10px;
	line-height: 1;
	gap: 10px;
	border-radius: 500px;
  animation: mymove .8s infinite;
}
@keyframes mymove {
  0%{
    transform: scale(0.8);
  }
  50%{
    transform: scale(1);
  }
  100%{
    transform: scale(0.8);
  }
}
.mobile-menu-section .mobile-menu .main-menu .accordion .accordion-button span {
  height: inherit;
  width: inherit;
  margin-right: 0px;
  background: #3577f0;
  line-height: 1.4;
  color: #ffff;
}
.badge.badge-new {
  background: #3577f0;
}
.badge.badge-hot {
  background: #ff497c;
}
/* mobile-menu */
.mobile-menu-overlay {
  background: #232323;
  cursor: crosshair;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  z-index: 92;
}
.mobile-menu-overlay.active {
  opacity: 0.5;
  visibility: visible;
}
.menu-icon {
  float: right;
  cursor: pointer;
}
.menu-icon svg {
	font-size: 18px;
	color: #13172b;
}
.mobile-menu-close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 22px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f2eeee;
  margin-bottom: 15px;
}
.mobile-menu-close h6 {
  display: inline-block;
  background: #ff497c;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
  margin-bottom: 0px;
}
.mobile-menu-logo a img {
  width: 100px;
}
.mobile-menu-close svg {
  font-size: 25px;
  color: #ffff;
}
.mobile-menu-section .mobile-menu {
  width: 280px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  border-right: 1px solid #dddddd82;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  margin-left: -281px;
  z-index: 99;
  height: 100vh;
  overflow-y: auto;
}
.mobile-menu-section .mobile-menu.active {
  display: block;
  margin-left: 0px;
}
.mobile-menu-section .mobile-menu .main-menu {
  float: left;
  width: 100%;
}
.mobile-menu-section .mobile-menu .main-menu ul {
  float: none;
}
.mobile-menu-section .mobile-menu .main-menu ul li {
  display: block;
  margin: 0;
  padding: 10px 20px;
  background: none;
}
.mobile-menu-section .mobile-menu .main-menu ul .menu-item-has-children {
  padding: 0;
}
.mobile-menu-section
  .mobile-menu
  .main-menu
  .accordion
  .accordion-button.collapsed {
  padding: 0;
  background: none;
}
.mobile-menu-section .mobile-menu .main-menu .accordion .accordion-button {
  background-color: inherit;
  padding: 0;
}
.mobile-menu-section .mobile-menu .main-menu .accordion .accordion-body {
  padding-top: 0;
  width: 100%;
  overflow: hidden;
  padding: 0;
}
.mobile-menu-section .mobile-menu .main-menu ul li .submenu {
  position: inherit;
  opacity: 1;
  visibility: visible;
  margin: 0px;
  padding: 5px 0px;
  box-shadow: none;
  min-width: inherit;
}
.mobile-menu-section .mobile-menu .main-menu ul li .submenu ul li a {
  padding: 0;
}
@media (max-width: 1199.98px) {
  .main-menu ul li {
    margin-left: 30px;
  }
}
@media (max-width: 991.98px) {
  .main-menu ul li a {
    padding: 2px 0px;
  }
}
@media (max-width: 768.98px) {
  .badge {
    padding: 3px 10px;
  }
}
@media (max-width: 576px) {
  .menu-icon {
    padding-top: 5px;
  }
  .header-right {
    padding-top: 4px;
  }
}
