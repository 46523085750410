.faq-collap .accordion {
  border: none;
}
.accordion {
  --bs-accordion-border-color: none;
  --bs-accordion-border-width: none;
}
.accordion .accordion-button {
  border-radius: 5px;
  padding: 15px 21px;
  font-size: 20px;
  color: #13172b;
  text-align: left;
  background-color: #f5f5f5;
}
.accordion .accordion-button span {
  height: 38px;
  width: 38px;
  background: #fff;
  text-align: center;
  line-height: 38px;
  border-radius: 500px;
  margin-right: 10px;
  color: #5a5858;
}
.accordion .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.accordion .accordion-button:not(.collapsed) {
  color: #3577f0;
}
.accordion .accordion-item {
  color: #5a5858;
  border: none;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 5px;
}
.accordion .accordion-item:last-child {
  margin: 0px;
}
.accordion .accordion-body {
  padding-top: 0;
}
.accordion .accordion-body p {
  font-size: 16px;
  line-height: 26px;
  color: #5a5858;
  border-top: 1px solid#fff;
  text-transform: none;
  padding-top: 15px;
}
/* faq-user-form */
.faq-user-form {
  padding: 25px;
  border-radius: 5px;
  background: #f9f3f0;
  border: 1px solid #f2f3fc;
}
.faq-img {
  margin-bottom: 40px;
}
.faq-img img {
  width: 100%;
}
.faq-title h2 {
  font-size: 30px;
  line-height: 30px;
  color: #13172b;
  font-weight: 600;
  margin-bottom: 25px;
}
.faq-title P {
  line-height: 26px;
  margin-bottom: 25px;
}
@media (max-width: 420px) {
  .accordion .accordion-button {
    padding: 10px 8px;
  }
}
