.fewture-product-slider {
  padding-bottom: 70px;
  position: relative;
}
.fewture-next-prev {
  position: absolute;
  top: -78px;
  right: 118px;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
  padding-bottom: 0px;
}
.fewture-next-prev div {
  position: absolute;
  width: 50px;
  text-align: center;
  height: 50px;
  cursor: pointer;
  color: #ff497c;
  border: 1px solid#ff497c;
  border-radius: 50px;
  transition: 0.3s;
}
.fewture-next-prev div:hover {
  background: #ff497c;
  color: #fff;
}
.fewture-next-prev div.swiper-button-disabled {
  background: #ff497c;
  z-index: 9;
  opacity: 0.5;
}
.fewture-product-slider .button-next-slide3 {
  margin-left: 70px;
}
.fewture-next-prev div svg {
  font-size: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 991.98px) {
  .fewture-next-prev div {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 420px) {
  .fewture-next-prev {
    right: 94px;
    top: -86px;
  }
  .fewture-next-prev div.button-next-slide3 {
    margin-left: 51px;
  }
  .fewture-next-prev div {
    width: 35px;
    height: 35px;
  }
  .fewture-next-prev div svg {
    font-size: 15px;
  }
}
