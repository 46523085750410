.team-img {
  position: relative;
  z-index: 1;
}
.team-img img {
  width: 100%;
  border-radius: 5px;
}
.team-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  background: #961e1e5e;
  bottom: 0;
  left: 0;
  transition: all .4s ease;
  opacity: 0;
  border-radius: 5px;
}
.team-card:hover .team-img::after{
  height: 100%;
  opacity: 1;
}
.team-social-info {
  padding: 10px;
  background: #fff;
  border-radius: 5px 0px 0px 0px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleY(0);
  transform-origin: bottom;
  opacity: 0;
  transition: all 0.4s ease;
  z-index: 1;
}
.team-card:hover .team-social-info {
  opacity: 1;
  transform: scaleY(1);
}
.team-social-info ul li a {
  height: 50px;
  width: 50px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: #3577f0;
  font-size: 16px;
  transition: all 0.3s ease;
}
.team-social-info ul li a:hover {
  background: #ff497c;
  color: #ffff;
}
.team-info h4 {
  font-size: 20px;
  line-height: 34px;
  color: #13172b;
  font-weight: 500;
  margin-bottom: 0;
}
.team-info span {
  font-size: 18px;
  line-height: 34px;
  color: #696969;
  font-weight: 400;
}
.team-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.icons.icon2 {
  display: none;
  transition: all 0.5s ease;
}
.icons.icon2 {
  transition: all 0.5s ease;
}
.model-icon .icons {
  height: 50px;
  width: 50px;
  background: #ff497c;
  text-align: center;
  line-height: 55px;
  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  transition: all 0.5s ease;
}
.team-card:hover .icons.icon1 {
  display: none;
}
.team-card:hover .icons.icon2 {
  display: block;
}
