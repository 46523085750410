.single-review {
  border: 1px solid #e4f2ff;
  border-radius: 0px 5px 5px 5px;
  padding: 20px;
}
.single-review h2 {
  font-size: 26px;
  line-height: 35px;
  color: #13172b;
  margin-bottom: 20px;
}
.customer-wraper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.customer-img {
  margin-right: 20px;
}
.inner-pd {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
}
.customer-details {
  display: flex;
}
.customer-reply a {
  border-radius: 500px;
  font-size: 18px;
  color: #13172b;
  line-height: 18px;
  display: inline-block;
  transition: all 0.3s ease;
}
.customer-reply a:hover {
  color: #ff497c;
}
.customer-names h4 {
  color: #13172b;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}
.customer-names span {
  font-size: 14px;
  color: #5a5858;
  line-height: 24px;
}
.grade {
  color: #5a5858;
  margin-left: 9px;
}
.customer-rating {
  margin-top: 5px;
}
.customer-rating span svg {
  color: #ffa800;
}
.customer-content p {
  line-height: 26px;
  color: #5a5858;
  margin-bottom: 0px;
}
.ml-45 {
  margin-left: 45px;
}
.customer-single-pb {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid#ffffff;
}
.mt-20 {
  margin-top: 20px;
}
@media (max-width: 575.98px) {
  .customer-reply a {
    padding: 8px 21px;
    font-size: 15px;
  }
}
